/**
 * Extracts the input name from a CMS name.
 *
 * @param {string} name - The CMS name.
 * @returns {string} The input name.
 *
 * @example
 * inputNameFromCmsName('newsletterModal_email') // 'email'
 */
export const inputNameFromCmsName = (name) => {
    return name.split('_')[1]
}
