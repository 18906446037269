import {colors} from '../../../constants/colors'

export default {
    baseStyle: {
        submitButton: {
            background: colors.black,
            backgroundColor: colors.black,
            color: colors.white,
            display: 'flex',
            justifyContent: 'space-between',
            _hover: {
                background: colors.black,
                backgroundColor: colors.black,
                color: colors.white
            },
            width: '100%'
        },
        cancelButton: {
            background: colors.transparent,
            backgroundColor: colors.transparent,
            color: colors.black,
            border: '1px solid',
            borderColor: colors.black,
            display: 'flex',
            justifyContent: 'space-between',
            _hover: {
                background: colors.transparent,
                backgroundColor: colors.transparent,
                color: colors.black
            },
            width: '100%'
        }
    }
}
