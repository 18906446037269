import {gql} from 'graphql-request'
import {
    LINK_FRAGMENT_FOR_LARGE_QUERY,
    MEDIA_FRAGMENT_FOR_LARGE_QUERY,
    THEME_FRAGMENT
} from './fragments'

export const blogArticleQuery = gql`
    ${LINK_FRAGMENT_FOR_LARGE_QUERY}
    ${MEDIA_FRAGMENT_FOR_LARGE_QUERY}
    fragment RichTextFragment on RichTextContent {
        typeName: __typename
        heading
        content {
            json
            links {
                entries {
                    inline {
                        typeName: __typename
                        ...LinkFragmentForLargeQueryFragment
                    }
                }
                assets {
                    block {
                        ...ImageVideoAssetFragment
                    }
                }
            }
        }
    }
    ${THEME_FRAGMENT}
    query ArticleCollection($locale: String!, $slug: String!) {
        articleCollection(where: {slug: $slug}, limit: 1, locale: $locale) {
            items {
                writtenBy
                reviewer
                certifiedBy
                factCheckedBy
                publishedOn
                disclaimer
                sectionsCollection(limit: 15) {
                    items {
                        ... on ArticleHeroSection {
                            typeName: __typename
                            heading
                            image {
                                ...MediaFragmentForLargeQuery
                            }
                        }
                        ... on RichTextContent {
                            ...RichTextFragment
                        }
                        ... on List {
                            typeName: __typename
                            heading
                            headingType
                            nonReferenceItems
                            listType
                        }
                        ... on ContentGrid {
                            typeName: __typename
                            banner {
                                ... on CtaBanner {
                                    title
                                    cta {
                                        ...LinkFragmentForLargeQueryFragment
                                    }
                                    banner {
                                        ...MediaFragmentForLargeQuery
                                    }
                                }
                            }
                            heading
                            subTitle
                            longDescription
                            cta {
                                ...LinkFragmentForLargeQueryFragment
                            }
                            itemsCollection(limit: 12) {
                                items {
                                    ... on Article {
                                        typeName: __typename
                                        cardTitle
                                        articleCategoriesCollection(limit: 1) {
                                            items {
                                                ... on ArticleCategory {
                                                    name
                                                }
                                            }
                                        }
                                    }
                                    ... on Card {
                                        typeName: __typename
                                        cardTitle: title
                                        subtitle
                                        cardCta: cta {
                                            ...LinkFragmentForLargeQueryFragment
                                        }
                                        cardImage: media {
                                            ...MediaFragmentForLargeQuery
                                        }
                                        description
                                    }
                                }
                            }
                        }
                        ... on Carousel {
                            typeName: __typename
                            heading
                            description
                            cta {
                                ...LinkFragmentForLargeQueryFragment
                            }
                            sectionName
                            fixedWidth
                            theme {
                                ...ThemeFragment
                            }
                            itemsCollection(limit: 10) {
                                items {
                                    ... on Product {
                                        contentfulTitle
                                        name
                                        description
                                        regularPrice
                                        salePrice
                                        brand {
                                            ... on Brand {
                                                name
                                            }
                                        }
                                        image {
                                            ...MediaFragmentForLargeQuery
                                        }
                                        productId
                                        currency
                                    }

                                    ... on Article {
                                        cardTitle
                                        cardImage {
                                            ...MediaFragmentForLargeQuery
                                        }
                                        cardCta {
                                            ...LinkFragmentForLargeQueryFragment
                                        }
                                    }
                                }
                            }
                        }
                        ... on TextAsset {
                            typeName: __typename
                            text
                            media {
                                ...MediaFragmentForLargeQuery
                            }
                        }
                        ... on Content {
                            typeName: __typename
                            title
                            sectionName
                            text
                            media {
                                ...MediaFragmentForLargeQuery
                            }
                        }
                    }
                }
            }
        }
    }
`
