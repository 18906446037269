import {colors} from '../../../constants'

export default {
    baseStyle: {
        container: {
            alignItems: 'center',
            flexDirection: {base: 'column-reverse', lg: 'row'},
            justifyContent: {base: 'flex-start', lg: 'space-between'},
            gap: {base: '20px', lg: '69px'},
            w: '100%'
        },
        ctaSectionContainer: {
            minWidth: {base: '100%', lg: '450px', xl: '530px'},
            gap: {base: '25px', lg: '30px'},
            marginY: {base: '20px'}
        },
        heroImageContainer: {
            maxHeight: '729px',
            width: '100%',
            maxWidth: {base: '1057px', xl: '100%'},
            height: {
                base: '286px',
                sm: '340px',
                md: '400px',
                lg: '600px',
                xl: '729px'
            },
            borderRadius: '12px',
            overflow: 'hidden',
            div: {
                video: {
                    objectFit: 'cover'
                }
            }
        },
        button: {
            width: '100%',
            justifyContent: 'space-between',
            height: 'auto',
            background: colors.lightBeigeTransparent,
            backgroundColor: colors.lightBeigeTransparent,
            border: '1px solid',
            borderColor: colors.greyTransparent,
            padding: {base: '19px 14px', lg: '30px 18px'},
            borderRadius: {base: '98px', lg: '43.5px'},
            fontSize: {base: '16px', lg: '24px'},
            color: 'black'
        }
    }
}
