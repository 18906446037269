import {gql} from 'graphql-request'
import {LINK_FRAGMENT, MEDIA_FRAGMENT, THEME_FRAGMENT} from './fragments'

export const getContentGridQuery = gql`
    ${LINK_FRAGMENT}
    ${MEDIA_FRAGMENT}
    ${THEME_FRAGMENT}
    query ContentGrid($locale: String!, $sectionName: String!) {
        contentGridCollection(
            where: {sectionName: $sectionName}
            locale: $locale
            limit: 1
        ) {
            items {
                banner {
                    ... on CtaBanner {
                        title
                        cta {
                            ...LinkFragment
                        }
                        banner {
                            ...MediaFragment
                        }
                    }
                }
                columns {
                    ... on ResponsiveValues {
                        base
                        sm
                        md
                        lg
                        xl
                    }
                }
                heading
                headingType
                subTitle
                cta {
                    ...LinkFragment
                }
                itemsCollection(limit: 12) {
                    items {
                        ... on Article {
                            cardTitle
                            cardCta {
                                ...LinkFragment
                            }
                            articleCategoriesCollection(limit: 1) {
                                items {
                                    ... on ArticleCategory {
                                        name
                                    }
                                }
                            }
                            slug
                            cardImage {
                                ...MediaFragment
                            }
                            cardDescription
                        }
                        ... on ProductCategory {
                            name
                            subTitle
                            theme {
                                ...ThemeFragment
                            }
                            cta {
                                ...LinkFragment
                            }
                            banner {
                                ...MediaFragment
                            }
                        }
                        ... on Card {
                            title
                            subtitle
                            cta {
                                ...LinkFragment
                            }
                            media {
                                ...MediaFragment
                            }
                            description
                        }
                    }
                }
            }
        }
        articleCategoryCollection {
            items {
                cta {
                    ...LinkFragment
                }
                name
            }
        }
    }
`
