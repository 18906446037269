export default {
    baseStyle: {
        addToCartButton: {
            padding: '16px 48px',
            width: '100%',
            fontSize: '16px',
            borderRadius: '33px',
            fontWeight: '300',
            color: 'white',
            /* 
            Overriding default background color with hover
            */
            _hover: {},
            _active: {
                background:
                    'linear-gradient(270deg, #000000, #000000, #192A2B, #335555, #4C7F80, #65A9AA, #65A9AA)',
                backgroundPosition: '0% 0'
            },
            _disabled: {
                background: '#D2D2D2',
                color: '#2A3030',
                cursor: 'not-allowed'
            },
            background:
                'linear-gradient(270deg, #000000, #000000, #192A2B, #335555, #4C7F80, #65A9AA, #65A9AA)',
            backgroundSize: '300% 100%',
            backgroundPosition: '100% 0',
            transition: 'background-position 0.5s ease-in-out'
        }
    }
}
