import {gql} from 'graphql-request'
import {LINK_FRAGMENT, MEDIA_FRAGMENT} from './fragments'

export const getHomeHeroSectionQuery = gql`
    ${LINK_FRAGMENT}
    ${MEDIA_FRAGMENT}
    query HomeHeroSection($locale: String!, $sectionName: String!) {
        homeHeroSectionCollection(
            limit: 1
            locale: $locale
            where: {sectionName: $sectionName}
        ) {
            items {
                heading
                subTitle
                ctaTop {
                    ...LinkFragment
                }
                ctaBottom {
                    ...LinkFragment
                }
                media {
                    ...MediaFragment
                }
                mediaAlignment
                numberOfColumns
            }
        }
    }
`
