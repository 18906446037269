import {colors, eventSectionItemWidth} from '../../../constants'

export default {
    baseStyle: {
        a: {
            marginTop: {base: '20px', lg: '77px'},
            backgroundColor: 'transparent',
            border: '1px solid',
            borderColor: `${colors.light}`,
            color: `${colors.light}`,
            borderRadius: {base: '30px', lg: '20px'},
            fontWeight: '300',
            // _hover: {
            //     backgroundColor: `${colors.light}`,
            //     textDecoration: 'none'
            // },
            paddingRight: {base: '82px', lg: '50px'},
            paddingLeft: {base: '82px', lg: '50px'},
            paddingTop: {base: '8px', lg: '6px'},
            paddingBottom: {base: '8px', lg: '6px'},
            fontFamily: 'Roboto',
            width: 'fit-content',
            fontSize: 'md',
            alignSelf: ['center', 'center', 'center', 'flex-start']
        },
        h4: {
            marginTop: {base: '0', lg: '52px'},
            fontSize: '3xl',
            fontWeight: 'normal',
            color: '#FFF',
            textAlign: ['center', 'center', 'center', 'left'],
            fontFamily: 'Raleway'
        },
        h2: {},
        p: {
            marginTop: {base: '20px', lg: '32px'},
            // textAlign: 'center'
            fontSize: 'md',
            textAlign: ['center', 'center', 'center', 'left'],
            fontFamily: 'Roboto'
        },
        scrollerStack: {
            overflowX: 'scroll',
            position: 'relative',
            width: 'full',
            wrap: 'nowrap',
            spacing: 30,
            direction: 'row',
            py: '1'
        }
    },
    variants: {
        event: {
            container: {
                bg: colors.eventSectionGreen,
                paddingTop: {base: '16px', lg: '78px'},
                paddingBottom: {base: '16px', lg: '78px'},
                paddingLeft: {base: '20px', lg: '64px'},
                paddingRight: {base: '20px', lg: '54px'},
                fontSize: 'md',
                justify: 'space-between',
                direction: {base: 'column', lg: 'row'},
                alignItems: {base: 'center', lg: 'flex-start'},
                gap: {base: '20px', lg: '0px'},
                maxWidth: '100vw',
                overflowX: 'hidden'
            },
            cardWidth: {
                width: eventSectionItemWidth
            }
        },
        service: {
            container: {
                paddingTop: {base: '20px', lg: '46px'},
                paddingBottom: {base: '16px', lg: '78px'},
                fontSize: 'md',
                justify: 'space-between',
                direction: {base: 'column', lg: 'row'},
                alignItems: {base: 'center', lg: 'flex-start'},
                gap: {base: '20px', lg: '0px'},
                maxWidth: 'calc(100vw - 36px)'
            },
            cardWidth: {
                width: '100%'
            }
        }
    }
}
