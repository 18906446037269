import {Link} from '@chakra-ui/react'
import React from 'react'
import {v4 as uuidv4} from 'uuid'

export const LinkRenderer = (node, children) => {
    const isNested = node.data?.uri && node.data?.uri === '#' ? true : false

    // TODO: Add title case for text
    return (
        <Link
            key={uuidv4()}
            isNested={isNested}
            href={node.data?.uri}
            textDecoration="underline"
            isExternal
        >
            {children}
        </Link>
    )
}
