import {getCarouselCollectionQuery} from '../queries'
import {useContentfulQueryWithHook} from './use-contentful-query.hook'

export const useCarouselSection = (sectionName) => {
    const {data, error} = useContentfulQueryWithHook(
        sectionName,
        getCarouselCollectionQuery,
        {
            sectionName: sectionName[0]
        }
    )

    return {data, error}
}
