/**
 * Todo Item - Need to Improve Header Query Using Fragments
 */
import {gql} from 'graphql-request'
import {
    LINK_FRAGMENT,
    IMAGE_VIDEO_ASSET_FRAGMENT,
    RICH_TEXT_COMPONENT_FRAGMENT
} from './fragments'

export const getstaticTemplatePageCollectionQuery = gql`
    ${LINK_FRAGMENT}
    ${RICH_TEXT_COMPONENT_FRAGMENT}
    ${IMAGE_VIDEO_ASSET_FRAGMENT}
    query staticTemplatePageQuery($locale: String!, $slug: String!) {
        pageCollection(limit: 1, locale: $locale, where: {slug: $slug}) {
            items {
                contentfulTitle
                slug
                sectionsCollection(limit: 5) {
                    items {
                        ...RichTextFragment
                    }
                }
            }
        }
    }
`

export const getLeftMenuQuery = gql`
    query staticTemplatePageQuery($locale: String!, $slugs: [String]!) {
        pageCollection(
            limit: 10
            locale: $locale
            where: {slug_in: $slugs}
            order: slug_DESC
        ) {
            items {
                metaPageTitle
                slug
            }
        }
    }
`
