import {useMutation} from '@tanstack/react-query'
import {useIntl} from 'react-intl'
import {changePassword, resetPassword} from '..'

export const useResetPasswordMutation = () => {
    const {locale} = useIntl()
    return useMutation(async (email) => {
        return resetPassword(email, locale)
    })
}

export const useChangePasswordMutation = () => {
    return useMutation(async ({newPassword, token}) => {
        return changePassword({token, newPassword})
    })
}
