import {getContentGridQuery} from '../queries'
import {useContentfulQueryWithHook} from './use-contentful-query.hook'

export const useContentGridSection = (queryKey) => {
    const {data, error} = useContentfulQueryWithHook(
        queryKey,
        getContentGridQuery,
        {
            sectionName: queryKey[0]
        }
    )
    return {data, error}
}
