import {gql} from 'graphql-request'
import {MEDIA_FRAGMENT} from './fragments'

export const getHomeVideoSectionQueryNew = gql`
    ${MEDIA_FRAGMENT}
    query HomeVideoSection($locale: String!, $sectionName: String!) {
        textAssetCollection(
            limit: 1
            locale: $locale
            where: {name: $sectionName}
        ) {
            items {
                media {
                    ...MediaFragment
                }
                textInAsset
                text
                subText
            }
        }
    }
`
