import {Text} from '@salesforce/retail-react-app/app/components/shared/ui'
import React from 'react'
import {v4 as uuidv4} from 'uuid'

/**
 * TO DO: Nested Children inside Text nodes is creating unique key alerts from React
 * We need to make sure they are addressed
 *
 */
export const Paragraph = (node, children) => {
    return (
        <Text className="p" key={uuidv4()}>
            {children}
        </Text>
    )
}
