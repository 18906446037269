import {contentfulQueryKeys} from '../../../constants'
import {getAllHeaderCollectionQuery} from '../queries'
import {useContentfulQueryWithHook} from './use-contentful-query.hook'

/* 
    hooks for fetching all header collections
*/
export const useAllHeaderCollections = () => {
    const {data, error} = useContentfulQueryWithHook(
        contentfulQueryKeys.pageHeaderQuery,
        getAllHeaderCollectionQuery
    )

    return {data, error}
}
