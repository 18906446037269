import {gql} from 'graphql-request'

export const RICH_TEXT_COMPONENT_FRAGMENT = gql`
    fragment RichTextFragment on RichTextContent {
        heading
        sectionName
        content {
            json
            links {
                entries {
                    inline {
                        typeName: __typename
                        ...LinkFragment
                    }
                }
                assets {
                    block {
                        ...ImageVideoAssetFragment
                    }
                }
            }
        }
    }
`
