import {useQuery} from '@tanstack/react-query'
import {gqlFetchContentful} from '../../../config'
import {contentfulQueryKeys} from '../../../constants'
import {messagesQuery} from '../queries'

export const useMessages = (locale) => {
    const {data, error} = useQuery(contentfulQueryKeys.messages, () =>
        gqlFetchContentful(messagesQuery, {locale: locale}, {enabled: !!locale})
    )

    return {data, error}
}
