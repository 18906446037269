import {colors} from '../../../constants'

export default {
    baseStyle: {
        container: {
            minWidth: 'xs',
            width: 'full',
            background: colors.footerBgGradient,
            padding: {base: '20px 14px 40px', xl: '0'},
            position: 'relative',
            zIndex: '2'
        },
        footerSectionContainer: {
            display: 'flex',
            flexDirection: {base: 'column', lg: 'row'},
            alignItems: 'center',
            justifyContent: {base: 'center', lg: 'space-between'},
            padding: {base: '0px 0px 20px 0px', lg: '0px 0px 40px 0px'},
            gap: {base: '20px', lg: '0px'}
        },
        subscribe: {
            width: {base: '100%', lg: 'auto'},
            display: 'grid',
            gridTemplateColumns: {base: '1fr', lg: 'max-content max-content'},
            alignItems: 'center',
            justifyContent: {base: 'center', lg: 'space-between'},
            gap: {base: '20px', lg: '30px'},
            paddingBottom: {base: '40px', lg: '0px'}
        },
        subscribeHeading: {
            width: '100%',
            fontSize: '24px',
            lineHeight: '28.18px',
            fontFamily: 'Raleway',
            color: colors.textDark,
            fontWeight: '300',
            textAlign: {base: 'center', lg: 'left'}
        },
        subscribeInputBox: {
            width: '100%',
            maxWidth: '471px',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0',
            minWidth: {base: '315px', lg: '320px'}
        },
        subscribeField: {
            width: '100%',
            background: 'white',
            border: '1px solid #E6E6E6',
            borderRadius: '33px',
            margin: 0,
            height: '44px',
            _placeholder: {
                fontSize: '16px'
            },
            padding: '0px 44px 0px 12px'
        },
        roundedBtn: {
            display: 'flex',
            background: colors.btnGradient,
            color: colors.textLight,
            borderRadius: '34px',
            fontWeight: '300',
            _hover: {
                background: colors.btnGradient,
                textDecoration: 'none'
            },
            padding: '8px 14px',
            minWidth: {base: '163px', lg: 'auto'},
            justifyContent: 'center',
            alignItems: 'center'
        },
        activeLocale: {
            background: colors.btnGradient,
            backgroundColor: 'none',
            color: colors.textLight,
            borderRadius: '34px',
            fontWeight: '300',
            _hover: {
                background: colors.btnGradient,
                backgroundColor: 'none',
                textDecoration: 'none'
            }
        },
        disabledLocale: {
            background: colors.btnGradient,
            backgroundColor: 'none',
            opacity: '40%',
            color: colors.textLight,
            borderRadius: '34px',
            fontWeight: '300',
            _hover: {
                background: colors.btnGradient,
                textDecoration: 'none'
            }
        },
        divider: {
            width: '100%',
            height: '0px',
            border: '1px solid rgba(43, 47, 49, 0.12)'
        },
        footerImageContainer: {
            display: 'flex',
            margin: {base: '0px', md: '0px'},
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-end',
            overflow: 'hidden',
            height: {base: '200px', md: '300px', lg: '323px'}
        },
        image: {
            width: {base: '100%', lg: '554px'}
        },
        footerMeta: {
            display: 'flex',
            flexDirection: {base: 'column-reverse', lg: 'row'},
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '33px',
            padding: {base: '20px 0', lg: '40px 0 0 0'}
        },
        copyright: {
            color: colors.textDark,
            lineHeight: {base: '18.75px', lg: '23.44px'},
            fontSize: {base: '16px', lg: '20px'},
            fontWeight: '300'
        },
        socialIcons: {
            display: 'flex',
            justifyContent: {base: 'center', lg: 'flex-end'},
            alignItems: 'center',
            gap: '33px',
            color: colors.iconDark
        }
    },
    parts: ['container', 'content', 'bodyContainer', 'logo', 'icons', 'signout']
}
