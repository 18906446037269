import {gql} from 'graphql-request'
import {IMAGE_VIDEO_ASSET_FRAGMENT} from '.'

export const MEDIA_FRAGMENT = gql`
    ${IMAGE_VIDEO_ASSET_FRAGMENT}
    fragment MediaFragment on Media {
        typeName: __typename
        desktop {
            ...ImageVideoAssetFragment
        }
        mobile {
            ...ImageVideoAssetFragment
        }
        blur
        desktopRatio
        mobileRatio
        position
        sys {
            id
        }
    }
`

export const MEDIA_FRAGMENT_FOR_LARGE_QUERY = gql`
    ${IMAGE_VIDEO_ASSET_FRAGMENT}
    fragment MediaFragmentForLargeQuery on Media {
        desktop {
            ...ImageVideoAssetFragment
        }
        mobile {
            ...ImageVideoAssetFragment
        }
    }
`
