import {colors} from '../../../constants'

export default {
    baseStyle: {
        container: {
            padding: {base: '20px 16px', md: '30px 60px', xl: '40px 96px'}
        },
        modalContent: {
            margin: {base: '20px', md: '0px'}
        },
        modalBody: {
            display: 'flex',
            flexDirection: {base: 'column', md: 'row'}
        },
        flexSection: {
            width: {base: '100%', md: '50%'}
        },
        submitButton: {
            color: colors.black,
            background: 'transparent',
            backgroundColor: 'transparent',
            textDecoration: 'underline',
            justifyContent: 'center',
            display: 'flex',
            height: 'fit-content',
            fontSize: '12px',
            fontWeight: '300',
            p: '0',
            _hover: {
                color: colors.black,
                fontWeight: '400',
                background: 'transparent',
                backgroundColor: 'transparent'
            },
            _active: {
                color: colors.black,
                fontWeight: '400',
                background: 'transparent',
                backgroundColor: 'transparent'
            },
            _disabled: {
                color: colors.black,
                background: 'transparent',
                backgroundColor: 'transparent'
            }
        }
    }
}
