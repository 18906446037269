import {gql} from 'graphql-request'
import {LINK_FRAGMENT, MEDIA_FRAGMENT} from './fragments'

export const getFooterQuery = gql`
    ${LINK_FRAGMENT}
    ${MEDIA_FRAGMENT}
    query Footer($locale: String!) {
        footerCollection(limit: 1, locale: $locale) {
            items {
                newsletterEmail {
                    ... on Form {
                        name
                        heading
                        headingType
                        submit {
                            ... on Link {
                                ...LinkFragment
                            }
                        }
                        fieldsCollection(limit: 1) {
                            items {
                                ... on Input {
                                    type
                                    label
                                    required
                                    placeholder
                                }
                            }
                        }
                    }
                }
                languageSwitcher {
                    ... on Input {
                        label
                        placeholder
                        type
                        required
                        name
                        errorMessage
                        options
                    }
                }
                cta {
                    ...LinkFragment
                }
                copyright
                socialMediaIconsCollection(limit: 4) {
                    items {
                        ... on Link {
                            ...LinkFragment
                        }
                    }
                }
                listsCollection(limit: 4) {
                    items {
                        ... on List {
                            heading
                            headingType
                            itemsCollection(limit: 7) {
                                items {
                                    ... on Link {
                                        ...LinkFragment
                                    }
                                }
                            }
                        }
                    }
                }
                mediaAssetCollection(limit: 1) {
                    items {
                        ... on Media {
                            ...MediaFragment
                        }
                    }
                }
            }
        }
    }
`
