import React, {useEffect} from 'react'
import {motion} from 'framer-motion'
import PropTypes from 'prop-types'
import {
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    HStack,
    InputGroup,
    InputLeftElement,
    Input,
    Button,
    Flex,
    Spinner,
    Box,
    useMediaQuery,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {FormattedMessage, useIntl} from 'react-intl'
import {styleConfigChakraKeys} from '../../../constants'
import {SearchIcon} from '../../icons'
import SearchProductTile from './search-product-tile'
import SearchSuggestions from './search-suggestions'

const MotionModalContent = motion(ModalContent)
export default function MobileSearch(props) {
    const [isDesktop] = useMediaQuery('(min-width: 1366px)')
    const styles = useMultiStyleConfig(styleConfigChakraKeys.search)
    const intl = useIntl()

    const {
        searchShow,
        onSubmitSearch,
        onSearchInputChange,
        shouldOpenPopover,
        searchInputRef,
        searchQuery,
        searchSuggestions,
        closeAndNavigate,
        onProductClick,
        recentSearches,
        ...rest
    } = props
    const isResult =
        searchSuggestions?.productSuggestions?.length > 0 ||
        searchSuggestions.isLoading

    const getModalVariant = (isResult) => {
        return isResult ? 'searchModalResult' : 'searchModal'
    }

    useEffect(() => {
        //for hide and show of search/Search Modal in mobile view
        //setting search input value to search query
        setTimeout(() => {
            if (searchShow && !isDesktop) {
                searchInputRef.current.value = searchQuery
            }
        }, 10)
    }, [searchShow])

    return (
        <Modal
            isOpen={!!(searchShow && !isDesktop)}
            variant={getModalVariant(isResult)}
            overflow="hidden"
            id="search-mobile-modal"
            blockScrollOnMount={!!isResult}
        >
            <MotionModalContent
                initial={{opacity: 0, top: '-100px'}}
                animate={{opacity: 1, top: '56px'}}
                exit={{opacity: 0, top: '-100px'}}
                transition={{duration: 0.4}}
                boxShadow="base"
                position="fixed"
                margin="0"
                maxWidth="unset"
            >
                <ModalHeader padding="22px 7px 10px 7px">
                    <form onSubmit={onSubmitSearch}>
                        <HStack
                            sx={{
                                '#search-input::-webkit-search-cancel-button': {
                                    display: 'none'
                                }
                            }}
                        >
                            <InputGroup {...styles.inputGroup}>
                                <InputLeftElement
                                    pointerEvents="none"
                                    top="5px"
                                    left="15px"
                                    display={{
                                        base: 'none',
                                        xl: 'block'
                                    }}
                                >
                                    <SearchIcon width="15px" />
                                </InputLeftElement>
                                <Input
                                    autoComplete="off"
                                    id="search-input"
                                    onChange={(e) => onSearchInputChange(e)}
                                    onFocus={() => shouldOpenPopover()}
                                    type="search"
                                    ref={searchInputRef}
                                    variant="filled"
                                    {...rest}
                                />
                                <Button {...styles.searchBtn} type="submit">
                                    <FormattedMessage
                                        defaultMessage="Search"
                                        id="search.action.searchtxt"
                                    />
                                </Button>
                            </InputGroup>
                        </HStack>
                    </form>
                </ModalHeader>
                {searchSuggestions?.productSuggestions?.length > 0 &&
                    searchShow && (
                        <ModalBody paddingBottom="0">
                            <Flex
                                display={searchShow ? 'flex' : 'none'}
                                position="absolute"
                                background="white"
                                left={0}
                                right={0}
                                height="100vh"
                                overflowY="auto"
                                paddingBottom="50px"
                                justifyContent="center"
                            >
                                {searchSuggestions.isLoading ? (
                                    <Spinner
                                        opacity={0.85}
                                        color="blue.600"
                                        zIndex="9999"
                                        marginTop="200px"
                                    />
                                ) : (
                                    <Box width="100%">
                                        <SearchSuggestions
                                            closeAndNavigate={closeAndNavigate}
                                            recentSearches={recentSearches}
                                            searchSuggestions={
                                                searchSuggestions
                                            }
                                        />
                                        <Box
                                            fontSize="20px"
                                            fontWeight="300"
                                            padding="0 15px"
                                            marginBottom="20px"
                                            color="black"
                                        >
                                            {intl.formatMessage({
                                                defaultMessage: 'Products',
                                                id: 'use_product.message.products'
                                            })}
                                        </Box>
                                        <Flex
                                            flexWrap="wrap"
                                            px="15px"
                                            pb="250px"
                                            gap="20px"
                                        >
                                            {searchSuggestions
                                                ?.productSuggestions?.length
                                                ? searchSuggestions?.productSuggestions
                                                      ?.slice(0, 4)
                                                      ?.map((product) => {
                                                          return (
                                                              <SearchProductTile
                                                                  key={
                                                                      product.ID
                                                                  }
                                                                  product={
                                                                      product
                                                                  }
                                                                  width={
                                                                      'calc(50% - 10px)'
                                                                  }
                                                                  onLinkClick={
                                                                      onProductClick
                                                                  }
                                                              />
                                                          )
                                                      })
                                                : ''}
                                        </Flex>
                                    </Box>
                                )}
                            </Flex>
                        </ModalBody>
                    )}
            </MotionModalContent>
        </Modal>
    )
}

MobileSearch.propTypes = {
    searchShow: PropTypes.bool,
    onSubmitSearch: PropTypes.func,
    onSearchInputChange: PropTypes.func,
    shouldOpenPopover: PropTypes.func,
    searchInputRef: PropTypes.object,
    searchQuery: PropTypes.string,
    searchSuggestions: PropTypes.object,
    closeAndNavigate: PropTypes.func,
    onProductClick: PropTypes.func,
    recentSearches: PropTypes.array
}
