export const inputTypes = {
    text: 'text',
    number: 'number',
    password: 'password',
    email: 'email',
    phone: 'phone',
    date: 'date',
    tel: 'tel',
    select: 'select',
    checkbox: 'checkbox',
    hidden: 'hidden'
}
