import {
    Box,
    Image,
    Text
} from '@salesforce/retail-react-app/app/components/shared/ui'
import PropTypes from 'prop-types'
import React from 'react'
import {useIntl} from 'react-intl'
import Link from '../link'
import {getTitleCase} from '../../hooks/use-title-case'

export default function TextImageCard({
    img,
    text,
    cardAlignment,
    hoverStyles,
    textAlignment,
    onLinkClick,
    link,
    boxShadow
}) {
    const intl = useIntl()
    return (
        <Link
            {...cardAlignment}
            {...hoverStyles}
            {...link}
            height="100%"
            onLinkClick={onLinkClick}
        >
            <Box width="100%" height="100%" boxShadow={boxShadow}>
                <Box height="100%" width="100%">
                    <Image
                        src={img.src}
                        alt={img.alt}
                        width="100%"
                        height="100%"
                        objectFit="cover"
                        borderRadius={{xl: '3px'}}
                    />
                </Box>
                <Text {...textAlignment}>
                    {getTitleCase(text, intl.locale)}
                </Text>
            </Box>
        </Link>
    )
}

TextImageCard.propTypes = {
    img: PropTypes.object,
    text: PropTypes.string,
    cardAlignment: PropTypes.object,
    hoverStyles: PropTypes.object,
    textAlignment: PropTypes.object,
    onLinkClick: PropTypes.func,
    link: PropTypes.shape({
        url: PropTypes.string,
        type: PropTypes.string,
        isExternal: PropTypes.bool,
        externalUrl: PropTypes.string
    })
}
