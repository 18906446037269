import React from 'react'
import {Box} from '@salesforce/retail-react-app/app/components/shared/ui'
import PropTypes from 'prop-types'

export const BoxAnimated = ({animationName, enableAnimation, ...rest}) => {
    return (
        <Box
            as="span"
            {...rest}
            {...(enableAnimation
                ? {animation: `${animationName} 0.4s ease-in-out forwards`}
                : {})}
        />
    )
}

BoxAnimated.propTypes = {
    animationName: PropTypes.object,
    enableAnimation: PropTypes.bool
}

export default BoxAnimated
