import {gql} from 'graphql-request'
import {LINK_FRAGMENT} from './fragments'

export const getPlpStaticFiltersQuery = gql`
    ${LINK_FRAGMENT}
    query plpStaticFilters($locale: String!) {
        listCollection(locale: $locale, where: {name: "plpStaticFilters"}) {
            items {
                itemsCollection(limit: 10) {
                    items {
                        ... on Link {
                            ...LinkFragment
                        }
                    }
                }
            }
        }
    }
`
