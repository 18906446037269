import {
    AccountIcon,
    BasketIcon,
    FacebookIcon,
    InstagramIcon,
    LinkedInIcon,
    TiktokIcon,
    LogOutIcon,
    LongArrowCategoryIcon,
    LongArrowBannerIcon,
    LongArrowIcon,
    RightArrowIcon,
    SearchIcon,
    TwitterIcon,
    AccountFrIcon
} from '../components/icons/index'

export const iconNames = {
    search: SearchIcon,
    cart: BasketIcon,
    account: AccountIcon,
    'account-fr': AccountFrIcon,
    logout: LogOutIcon,
    basket: BasketIcon,
    signin: AccountIcon,
    instagram: InstagramIcon,
    facebook: FacebookIcon,
    twitter: TwitterIcon,
    linkedin: LinkedInIcon,
    rightArrow: RightArrowIcon,
    longArrow: LongArrowIcon,
    longArrowCategory: LongArrowCategoryIcon,
    longArrowBanner: LongArrowBannerIcon,
    tiktok: TiktokIcon
}
