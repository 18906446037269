import {
    Box,
    Flex,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import React from 'react'

import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import {useStaticTemplatePage} from '../../../clients/contentful/hooks/use-static-template-page.hook'
import {options} from '../../../components/rich-text/Options'
import {styleConfigChakraKeys} from '../../../constants'

export default function Contact() {
    const {data: pageData} = useStaticTemplatePage('contact')
    const styles = useMultiStyleConfig(styleConfigChakraKeys.contactPage)

    const page = pageData?.pageCollection?.items[0]
    const pageContent = page?.sectionsCollection?.items

    return (
        <Flex {...styles.page} sx={styles.topBarSx}>
            <Box {...styles.content}>
                {pageContent?.map((item) => {
                    return (
                        item &&
                        item.content && (
                            <ContactPageContent pageContent={item} />
                        )
                    )
                })}
            </Box>
        </Flex>
    )
}

function ContactPageContent({pageContent}) {
    const styles = useMultiStyleConfig(styleConfigChakraKeys.contactPage)
    const {json, links} = pageContent.content

    return (
        <Box {...styles.richContent} sx={styles.richContentSx}>
            <Box as="h2" {...styles.contentHeading}>
                {pageContent?.heading}
            </Box>
            {documentToReactComponents(json, options(links))}
        </Box>
    )
}
