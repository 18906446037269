/**
 * Todo Item - Need to Improve Header Query Using Fragments
 */
import {gql} from 'graphql-request'
import {LINK_FRAGMENT, MEDIA_FRAGMENT} from './fragments'

export const getAllHeaderCollectionQuery = gql`
    ${LINK_FRAGMENT}
    ${MEDIA_FRAGMENT}
    query HeaderQuery($locale: String!) {
        headerCollection(limit: 1, locale: $locale) {
            items {
                rightCollection(limit: 10) {
                    items {
                        ... on Link {
                            ...LinkFragment
                        }
                    }
                }
                leftCollection(limit: 4) {
                    items {
                        ... on Link {
                            ...LinkFragment
                        }
                        ... on List {
                            sys {
                                id
                            }
                            name
                            heading
                            __typename
                            itemsCollection(limit: 6) {
                                items {
                                    ... on Link {
                                        ...LinkFragment
                                    }
                                    ... on List {
                                        sys {
                                            id
                                        }
                                        name
                                        heading
                                        __typename
                                        itemsCollection(limit: 8) {
                                            items {
                                                ... on Link {
                                                    ...LinkFragment
                                                }
                                                ... on List {
                                                    sys {
                                                        id
                                                    }
                                                    name
                                                    heading
                                                    __typename
                                                    itemsCollection(limit: 17) {
                                                        items {
                                                            ... on Link {
                                                                ...LinkFragment
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                bannerCollection(limit: 2) {
                    items {
                        ... on TextAsset {
                            media {
                                ...MediaFragment
                            }
                        }
                        text
                        cta {
                            ...LinkFragment
                        }
                    }
                }
            }
        }
    }
`
