// contentful cms client setup in react
// import {createClient} from 'contentful'

import {GraphQLClient} from 'graphql-request'
import {getSsrServiceUrl} from '../utils/site-utils'

// TODO: issue is that the client is not working allowing http requests to proxy
// export const client = createClient({
//     space: 'space',
//     accessToken: 'token',
//     host: 'localhost:3000',
//     secure: false,
//     proxy: {
//         url: '/mobify/proxy/contentful',
//         protocol: 'http',
//         host: 'localhost',
//         port: '3000'
//     }
// })

const ssrServiceUrl = getSsrServiceUrl()
const endpoint = `${ssrServiceUrl}/contentful`

const graphQLClient = new GraphQLClient(endpoint, {
    jsonSerializer: {
        parse: JSON.parse,
        stringify: JSON.stringify
    },
    headers: {
        'Content-Type': 'application/json'
    }
})
export const gqlFetchContentful = async (query, variables) => {
    const data = await graphQLClient.request(query, variables)
    return data
}
