import Link from '@salesforce/retail-react-app/app/components/link'
import {
    Box,
    Button,
    Flex,
    HStack,
    Text,
    VStack,

    // Hooks
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import PropTypes from 'prop-types'
import React from 'react'
import {useIntl} from 'react-intl'

import {RightChevronIcon} from '../icons'

// Others
import {
    AuthHelpers,
    useAuthHelper,
    useCustomerType
} from '@salesforce/commerce-sdk-react'
import useNavigation from '@salesforce/retail-react-app/app/hooks/use-navigation'
import {styleConfigChakraKeys} from '../../constants'

// CUSTOMIZE YOUR NAVIGATION BY ALTERING THESE VALUES
const SIGN_IN_HREF = '/login'

export default function LoginAction({itemLength, setShowLoading}) {
    const intl = useIntl()
    const navigate = useNavigation()
    const {isRegistered} = useCustomerType()
    const logout = useAuthHelper(AuthHelpers.Logout)
    const styles = useMultiStyleConfig(styleConfigChakraKeys.drawerMenu)

    const onSignoutClick = async () => {
        setShowLoading(true)
        await logout.mutateAsync()
        navigate('/login')
        setShowLoading(false)
    }

    return (
        <VStack align="stretch" spacing={0} {...styles.actions} px={0}>
            <Box {...styles.actionsItem}>
                {isRegistered ? (
                    <Flex flexDirection="column">
                        <Link
                            href="/account"
                            {...styles.mobileNavLink}
                            style={{'--index': itemLength}}
                        >
                            Account
                        </Link>
                        <Button
                            {...styles.signout}
                            variant="unstyled"
                            onClick={onSignoutClick}
                            paddingRight="0"
                            sx={{
                                '-webkit-tap-highlight-color': 'transparent'
                            }}
                        >
                            <Flex align="center">
                                <Text
                                    {...styles.signoutText}
                                    {...styles.mobileNavLink}
                                    style={{
                                        '--index': itemLength
                                    }}
                                    as="span"
                                >
                                    {intl.formatMessage({
                                        id: 'drawer_menu.button.Sign_out',
                                        defaultMessage: 'Sign Out'
                                    })}
                                    <RightChevronIcon {...styles.chevronIcon} />
                                </Text>
                            </Flex>
                        </Button>
                    </Flex>
                ) : (
                    <Link
                        href={SIGN_IN_HREF}
                        _hover={{textDecoration: 'none'}}
                        sx={{
                            '-webkit-tap-highlight-color': 'transparent'
                        }}
                    >
                        <HStack
                            {...styles.mobileNavLink}
                            style={{'--index': itemLength}}
                        >
                            <Text>
                                {intl.formatMessage({
                                    id: 'drawer_menu.link.sign_in',
                                    defaultMessage: 'Sign In'
                                })}
                            </Text>
                            <RightChevronIcon {...styles.chevronIcon} />
                        </HStack>
                    </Link>
                )}
            </Box>
        </VStack>
    )
}

LoginAction.propTypes = {
    itemLength: PropTypes.number,
    setShowLoading: PropTypes.func
}
