import {keyframes} from '@chakra-ui/react'

const rotateFrom45ToZeroAnimation = keyframes`
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0);
  }
`

const rotateFromMinus45ToZeroAnimation = keyframes`
  0% {
    transform: rotate(-45deg);
    opacity: 1;
  }
  90% {
    transform: rotate(0);
  }
  100% {
    opacity: 0;
  }
`

const scaleToTopAnimation = keyframes`
  0% {
    opacity: 0;
    scale: 0;
  }
  100% {
    opacity: 1;
    scale: 1;
    transform: translateY(9px);
  }
`

const scaleToBottomAnimation = keyframes`
  0% {
    opacity: 0;
    scale: 0;
  }
  100% {
    opacity: 1;
    scale: 1;
    transform: translateY(-9px);
  }
`

const closeIcon = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export default {
    rotateFrom45ToZeroAnimation,
    rotateFromMinus45ToZeroAnimation,
    scaleToTopAnimation,
    scaleToBottomAnimation,
    closeIcon
}
