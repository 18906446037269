import {motion} from 'framer-motion'
import React from 'react'
import {
    Box,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {styleConfigChakraKeys} from '../../constants'

export default function Overlay(props) {
    const styles = useMultiStyleConfig(styleConfigChakraKeys.overlay)

    return (
        <Box
            {...styles.overlay}
            as={motion.div}
            initial={{opacity: '0'}}
            animate={{opacity: '1'}}
            transition="opacity 0.2s linear 0.3s"
            {...props}
        ></Box>
    )
}
