import {colors} from '../../../constants/colors'

export default {
    baseStyle: {
        page: {
            fontSize: '16px',
            flexWrap: 'wrap',
            marginTop: {lg: '52px'},
            marginBottom: {base: '40px', lg: '100px'},
            mx: 'auto',
            width: '100%',
            maxWidth: '1903px',
            minHeight: '100vh'
        },
        topBarSx: {
            '.left-scroll': {
                'overflow-x': 'auto',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none'
            },
            '.left-scroll::-webkit-scrollbar': {
                display: 'none'
            }
        },
        topBar: {
            width: {base: '100%', lg: '25%'},
            flexDirection: {base: 'row', lg: 'column'},
            borderBottom: {base: `1px solid ${colors.brightGray}`, lg: 'none'},
            paddingLeft: {base: '20px', lg: '30px'},
            overflowX: 'auto'
        },
        content: {
            padding: {base: '0 16px', lg: '0'},
            width: {base: '100%', lg: '59%'}
        },
        leftBtn: {
            whiteSpace: 'nowrap',
            marginRight: '16px',
            width: {lg: 'fit-content'},
            marginBottom: {lg: '24px'}
        },
        tab: {
            p: '0 2px',
            color: '#667085',
            marginRight: '16px',
            _selected: {color: `${colors.blueCol}`}
        },
        tabLink: {
            fontSize: '14px',
            fontWeight: '300',
            display: 'inline-block',
            padding: '11px 0',
            lineHeight: '24px'
        },
        richContentSx: {
            h4: {
                fontSize: {base: '20px', lg: '24px'},
                lineHeight: {base: '24px', lg: '28px'},
                marginBottom: '20px',
                fontFamily: `'Raleway', sans-serif`,
                marginTop: '34px'
            },
            h3: {
                fontSize: {base: '20px', lg: '24px'},
                marginBottom: '20px',
                fontFamily: `'Raleway', sans-serif`
            },
            h2: {
                fontSize: {base: '24px', lg: '32px'},
                marginBottom: '24px',
                fontWeight: '600',
                fontFamily: `'Raleway', sans-serif`
            },
            p: {
                fontSize: {base: '14px', lg: '16px'},
                fontFamily: `'Roboto', sans-serif`,
                marginY: {base: '16px', lg: '20px'}
            },
            ul: {
                paddingLeft: '20px',
                marginBottom: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                li: {
                    p: {
                        marginBottom: '0px',
                        marginTop: '0px'
                    }
                }
            },
            img: {
                borderRadius: '12px'
            }
        },
        richContent: {
            color: `${colors.blueCol}`,
            fontWeight: '300',
            maxWidth: '730px',
            width: '100%'
        },
        contentHeading: {
            fontFamily: `'Raleway', sans-serif`,
            fontSize: {base: '24px', lg: '32px'},
            fontWeight: {base: '300', lg: '400'},
            lineHeight: '38px',
            marginBottom: {base: '20px', lg: '34px'},
            marginTop: {base: '20px', lg: '0'}
        }
    }
}
