import {useIntl} from 'react-intl'
import {useMemo} from 'react'
import _ from 'lodash'

export const toTitleCaseWithExceptions = (str) => {
    if (!str || !str.length) {
        return str
    }
    const exceptions = [
        'to',
        'in',
        'on',
        'at',
        'by',
        'for',
        'with',
        'about',
        'against',
        'between',
        'into',
        'through',
        'during',
        'before',
        'after',
        'above',
        'below',
        'from',
        'up',
        'down',
        'over',
        'under',
        'again',
        'further',
        'then',
        'once',
        'a',
        'an'
    ]
    return str
        .split(' ')
        .map((word, index) => {
            if (index !== 0 && exceptions.includes(word.toLowerCase())) {
                return word.toLowerCase()
            }
            return _.capitalize(word.toLowerCase())
        })
        .join(' ')
}

export function toSentenceCase(str) {
    if (typeof str !== 'string' || str.length === 0) {
        return str // Return the input if it's not a string or is empty
    }

    // Convert the entire string to lowercase and capitalize the first character
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const getTitleCase = (inputText, locale) => {
    if (locale === 'en-CA') {
        return toTitleCaseWithExceptions(inputText)
    }

    if (locale === 'fr-CA') {
        return toSentenceCase(inputText)
    }
    return toTitleCaseWithExceptions(inputText)
}

export const useCasing = ({isTitleCase = true, text}) => {
    const {locale} = useIntl()

    const updatedText = useMemo(() => {
        if (isTitleCase) {
            if (locale === 'en-CA') {
                return toTitleCaseWithExceptions(text)
            }

            if (locale === 'fr-CA') {
                return toSentenceCase(text)
            }
        }
        return text
    }, [locale, isTitleCase, text])

    return {updatedText, getTitleCase}
}
