import {gql} from 'graphql-request'

export const messagesQuery = gql`
    query Messages($locale: String!) {
        localizedMessagesCollection(limit: 1000, locale: $locale) {
            items {
                value
                id
            }
        }
    }
`
