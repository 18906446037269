import {contentfulQueryKeys} from '../../../constants'
import {getHomeVideoSectionQueryNew} from '../queries'
import {useContentfulQueryWithHook} from './use-contentful-query.hook'

export const useVideoSectionData = ({sectionName}) => {
    const {data, error} = useContentfulQueryWithHook(
        [...contentfulQueryKeys.getHomeVideoSectionQuery, sectionName],
        getHomeVideoSectionQueryNew,
        {
            sectionName
        }
    )

    return {data, error}
}
