import {contentfulQueryKeys} from '../../../constants'
import {getCookieModalQuery} from '../queries'
import {useContentfulQueryWithHook} from './use-contentful-query.hook'

/* 
    hooks for fetching all header collections
*/
export const useCookieModal = () => {
    const {data, error} = useContentfulQueryWithHook(
        contentfulQueryKeys.getCookieModalQuery,
        getCookieModalQuery
    )

    return {data, error}
}
