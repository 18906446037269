import {useQuery} from '@tanstack/react-query'
import {useIntl} from 'react-intl'
import {gqlFetchContentful} from '../../../config'

export const useContentfulQueryWithHook = (key, query, variables = {}) => {
    const {locale} = useIntl()
    return useQuery(key, () =>
        gqlFetchContentful(query, {locale, ...variables})
    )
}
