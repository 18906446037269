import {
    Image,
    AspectRatio
} from '@salesforce/retail-react-app/app/components/shared/ui'
import React from 'react'

export const Asset = (node, assetMap) => {
    const asset = assetMap?.get(node.data.target.sys.id)

    if (asset.contentType && asset.contentType === 'video/mp4') {
        return (
            <AspectRatio ratio={3 / 1.5}>
                <video width="320" height="240" autoPlay muted loop>
                    <source src={asset.url} type="video/mp4" />
                </video>
            </AspectRatio>
        )
    }

    return (
        <AspectRatio ratio={3 / 1.5}>
            <Image className="img" src={asset.url} objectFit="cover" />
        </AspectRatio>
    )
}
