import {classNames} from '../../utils/classNames'
import React from 'react'
import {getAssetUrl} from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'

// type IconProps = {
//     width?: number,
//     height?: number,
//     className?: string,
//     styles?: CSSProperties
// }

import {Image} from '@salesforce/retail-react-app/app/components/shared/ui'
import PropTypes from 'prop-types'

const iconPropTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string,
    styles: PropTypes.objectOf(PropTypes.string)
}

const LogoBlackIcon = ({width = 664, height = 150, className = '', styles}) => (
    <Image
        src={getAssetUrl('static/svgs/Logo--black.svg')}
        width={width}
        height={height}
        alt="Logo Black"
        className={classNames('icon__logo--black', className)}
        {...(styles && {style: styles})}
    />
)

LogoBlackIcon.propTypes = iconPropTypes

const LogoSmallBlackIcon = ({
    width = 155,
    height = 55,
    className = '',
    styles
}) => (
    <Image
        src={getAssetUrl('static/svgs/Logo-Small--black.svg')}
        width={width}
        height={height}
        alt="Logo Black"
        className={classNames('icon__logo-small--black', className)}
        {...(styles && {style: styles})}
    />
)

LogoSmallBlackIcon.propTypes = iconPropTypes

const LogoSmallWhiteIcon = ({
    width = 155,
    height = 55,
    className = '',
    styles
}) => (
    <Image
        src={getAssetUrl('static/svgs/Logo-Small--white.svg')}
        width={width}
        height={height}
        alt="Logo White"
        className={classNames('icon__logo-small--white', className)}
        {...(styles && {style: styles})}
    />
)

LogoSmallWhiteIcon.propTypes = iconPropTypes

const ArrowBottomIcon = ({
    width = 61,
    height = 103,
    className = '',
    styles
}) => (
    <Image
        src={getAssetUrl('static/svgs/Arrow-Bottom.svg')}
        width={width}
        height={height}
        alt="Arrow Bottom White"
        className={classNames('icon__arrow-bottom', className)}
        {...(styles && {style: styles})}
    />
)
ArrowBottomIcon.propTypes = iconPropTypes

const ArrowRightBlackIcon = ({
    width = 103,
    height = 61,
    className = '',
    styles
}) => (
    <Image
        src={getAssetUrl('static/svgs/Arrow-Right--black.svg')}
        width={width}
        height={height}
        alt="Arrow Right black"
        className={classNames('icon__arrow-right--black', className)}
        {...(styles && {style: styles})}
    />
)
ArrowRightBlackIcon.propTypes = iconPropTypes

const ArrowRightWhiteIcon = ({
    width = 103,
    height = 61,
    className = '',
    styles
}) => (
    <Image
        src={getAssetUrl('static/svgs/Arrow-Right--white.svg')}
        width={width}
        height={height}
        alt="Arrow Right White"
        className={classNames('icon__arrow-right--white', className)}
        {...(styles && {style: styles})}
    />
)

const FacebookIcon = ({width = 9, height = 18, className = '', styles}) => (
    <Image
        src={getAssetUrl('static/svgs/Facebook.svg')}
        width={width}
        height={height}
        alt="Facebook Icon"
        className={classNames('icon_Facebook', className)}
        {...(styles && {style: styles})}
    />
)

const InstagramIcon = ({width = 17, height = 16, className = '', styles}) => (
    <Image
        src={getAssetUrl('static/svgs/Instagram.svg')}
        width={width}
        height={height}
        alt="Instagram Icon"
        className={classNames('icon_Instagram', className)}
        {...(styles && {style: styles})}
    />
)
const TiktokIcon = ({width = 17, height = 16, className = '', styles}) => (
    <Image
        src={getAssetUrl('static/svgs/Tiktok.svg')}
        width={width}
        height={height}
        alt="Tiktok Icon"
        className={classNames('icon_Tiktok', className)}
        {...(styles && {style: styles})}
    />
)

const LinkedinIcon = ({width = 17, height = 16, className = '', styles}) => (
    <Image
        src={getAssetUrl('static/svgs/Linkedin.svg')}
        width={width}
        height={height}
        alt="Linkedin Icon"
        className={classNames('icon__Linkedin', className)}
        {...(styles && {style: styles})}
    />
)

const TwitterIcon = ({width = 21, height = 15, className = '', styles}) => (
    <Image
        src={getAssetUrl('static/svgs/Twitter.svg')}
        width={width}
        height={height}
        alt="Twitter Icon"
        className={classNames('icon__Twitter', className)}
        {...(styles && {style: styles})}
    />
)

const SoundIcon = ({width = 57, height = 50, className = '', styles}) => (
    <Image
        src={getAssetUrl('static/svgs/Sound.svg')}
        width={width}
        height={height}
        alt="Sound Icon"
        className={classNames('icon__Sound', className)}
        {...(styles && {style: styles})}
    />
)

const MenuCrossIcon = ({width = 57, height = 50, className = '', styles}) => (
    <Image
        src={getAssetUrl('static/svgs/Menu-Cross.svg')}
        width={width}
        height={height}
        alt="Menu Cross Icon"
        className={classNames('icon__Menu-Cross', className)}
        {...(styles && {style: styles})}
    />
)

const PlayButtonIcon = ({width = 17, height = 22, className = '', styles}) => (
    <Image
        src={getAssetUrl('static/svgs/Play.svg')}
        width={width}
        height={height}
        alt="Play Icon"
        className={classNames('icon__Play', className)}
        {...(styles && {style: styles})}
    />
)

const PauseButtonIcon = ({width = 17, height = 22, className = '', styles}) => (
    <Image
        src={getAssetUrl('static/svgs/Pause.svg')}
        width={width}
        height={height}
        alt="Pause Icon"
        className={classNames('icon__Pause', className)}
        {...(styles && {style: styles})}
    />
)

export {
    ArrowBottomIcon,
    ArrowRightBlackIcon,
    ArrowRightWhiteIcon,
    FacebookIcon,
    InstagramIcon,
    LinkedinIcon,
    LogoBlackIcon,
    LogoSmallBlackIcon,
    LogoSmallWhiteIcon,
    MenuCrossIcon,
    PauseButtonIcon,
    PlayButtonIcon,
    SoundIcon,
    TwitterIcon
}
