import {contentfulQueryKeys} from '../../../constants'
import {getNewsletterQuery} from '../queries'
import {useContentfulQueryWithHook} from './use-contentful-query.hook'

/* 
    hooks for fetching all header collections
*/
export const useNewsLetter = () => {
    const {data, error} = useContentfulQueryWithHook(
        contentfulQueryKeys.getNewsletterQuery,
        getNewsletterQuery
    )

    return {data, error}
}
