export default {
    baseStyle: {
        bookingsContainer: {
            paddingLeft: {base: '15px', xl: '36px'},
            paddingRight: {base: '15px', xl: '36px'}
        },
        bookingHeader: {
            marginBottom: {base: '20px', xl: '46px'},
            alignItems: {base: 'flex-start', xl: 'center'}
        },
        heading: {
            fontSize: {base: '24px', xl: '32px'},
            lineHeight: {base: '28px', xl: '38px'},
            fontWeight: {base: '300', xl: '400'},
            fontFamily: {base: 'Roboto', xl: 'Raleway'},
            cursor: 'auto',
            marginRight: '20px'
        },
        h4: {
            color: '#000000',
            fontWeight: '400',
            fontSize: {base: '2xl', lg: '3xl'},
            fontFamily: 'Raleway',
            paddingRight: '14px'
        },
        link: {
            color: '#000000',
            fontWeight: '300',
            fontSize: '16px',
            fontFamily: 'Roboto',
            whiteSpace: 'nowrap',
            _hover: {
                textDecoration: 'underline'
            },
            _active: {
                textDecoration: 'underline'
            }
        }
    }
}
