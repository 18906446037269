import {keyframes} from '@chakra-ui/react'

const mobileSearchAnimation = keyframes`
  0% {
    transform: translateY(-71px);
    opacity: 0;
  }
  100% {
    transform: translateY(52px);
    opacity: 1;
  }
`

const mobileSearchReverse = keyframes`
  0% {
    transform: translateY(52px);
    opacity: 1;
  }
  100% {
    transform: translateY(-71px);
    opacity: 0;
  }
`

const desktopSearchAnimation = keyframes`
  0% { 
    width: 0;
    opacity:0; 
    z-index: -1
  }
  100% { 
    width: 27%; 
    opacity:1; 
    z-index: 1
  }
`

const desktopSearchAnimationReverse = keyframes`
  0% { 
    width: 27%;
    opacity: 1; 
    z-index: 1 
  } 100% { 
    width: 0;
    opacity: 0; 
    z-index: -1
  }
`

export const searchAnimation = (searchShow) => {
    return {
        base: searchShow
            ? `${mobileSearchAnimation} 0.3s ease-in forwards`
            : `${mobileSearchReverse} 0.3s ease-in forwards`,
        xl: searchShow
            ? `${desktopSearchAnimation} 0.3s ease-in forwards`
            : `${desktopSearchAnimationReverse} 0.3s ease-in forwards`
    }
}
