import {colors} from '../../../constants'

export default {
    baseStyle: {
        card: {
            flexWrap: 'wrap',
            cursor: 'pointer'
        },
        title: {
            fontFamily: {
                base: `"Roboto" , sans-serif`,
                lg: `"Raleway" , sans-serif`
            },
            fontSize: '24px',
            lineHeight: {base: '28px', lg: '32px'},
            fontWeight: {base: '300', lg: '400'},
            color: '#000000'
        },
        contentLink: {
            fontFamily: `"Roboto" , sans-serif`,
            fontSize: '24px',
            lineHeight: '28px',
            fontWeight: '300',
            color: '#000000'
        }
    },
    variants: {
        primary: {
            card: {
                width: {base: '100%', lg: '70%'},
                marginBottom: {base: '32px', lg: '0'}
            },
            imgContainer: {
                width: {base: '100%', lg: '40%'},
                borderRadius: '12px',
                marginBottom: {base: '20px', lg: '0'}
            },
            content: {
                marginTop: {base: '0', lg: '93px'},
                width: {base: '100%', lg: '50%'},
                paddingLeft: {base: '0', lg: '23px'}
            },
            image: {
                borderRadius: {base: '8px', lg: '12px'},
                width: '100%'
            }
        },
        secondary: {
            card: {
                width: {base: '100%', lg: '32%'}
            },
            imgContainer: {
                marginBottom: {base: '13px', lg: '32px'},
                width: '100%',
                borderRadius: '12px'
            },
            image: {
                borderRadius: {base: '8px', lg: '12px'},
                width: '100%'
            },
            content: {
                width: {base: '100%', lg: '410px'}
            },
            title: {
                fontWeight: '300'
            }
        },
        tertiary: {
            card: {
                width: '100%',
                mb: {base: '40px', lg: '80px'}
            },
            imgContainer: {
                marginBottom: {base: '20px', lg: '13px'},
                width: '100%',
                borderRadius: '12px'
            },
            image: {
                borderRadius: {base: '8px', lg: '12px'},
                width: '100%'
            },
            title: {
                fontFamily: `"Raleway" , sans-serif`,
                fontSize: {base: '20px', lg: '32px'},
                lineHeight: {base: '24px', lg: '37px'},
                fontWeight: {base: '300', lg: '400'},
                mb: {base: '20px', lg: '32px'}
            },
            badge: {
                py: '8px',
                px: '20px',
                textTransform: 'uppercase',
                border: '0.6px solid black',
                color: colors.paleYellow,
                mr: '12px',
                fontWeight: '300',
                fontSize: '14px',
                borderRadius: '20px',
                minWidth: '103px',
                textAlign: 'center',
                mb: {base: '15px', lg: '9px'}
            }
        },
        defaultProps: {
            variant: 'primary'
        }
    }
}
