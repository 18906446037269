import {colors} from '../../../constants'

export default {
    baseStyle: {
        accordion: {
            width: '100%',
            minWidth: '314px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            padding: '20px 0'
        },
        accordionHeading: {
            fontWeight: '300',
            fontSize: '16px',
            lineHeight: '19px',
            color: colors.textDark
        },
        accordionItem: {
            padding: '2px 0px',
            border: 'none'
        },
        accordionButton: {
            padding: '0',
            height: 'auto'
        }
    }
}
