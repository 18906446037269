import {getSsrServiceUrl} from '../../utils/site-utils'
import {getConfig} from '@salesforce/pwa-kit-runtime/utils/ssr-config'

// add base ssr fetch function named client
const ssrServiceUrl = getSsrServiceUrl()

/**
 * Fetches data from the server.
 * @param {string} url - The URL to fetch data from.
 * @param {Object} options - The options to pass to the fetch function.
 * @param {Object} options.body - The body of the request.
 * @param {string} options.method - The method of the request.
 * @param {Object} options.headers - The headers of the request.
 * @returns {Promise<Object>} - A promise that resolves with the fetched data.
 */

const readStreamToJson = async (readableStream) => {
    try {
        const reader = readableStream.getReader()
        const {value: chunk} = await reader.read() // read a chunk
        const decoder = new TextDecoder()
        const jsonStr = decoder.decode(chunk) // decode chunk to string

        try {
            const json = JSON.parse(jsonStr) // parse string to JSON
            return json
        } catch {
            return jsonStr
        }
    } catch (err) {
        throw new Error('An error occurred while processing the request.')
    }
}

const ssrClient = async (url, {body, method = 'POST', headers = {}}) => {
    const res = await fetch(`${ssrServiceUrl}${url}`, {
        method,
        headers: {
            'Content-Type': 'application/json',
            ...headers
        },
        body: JSON.stringify(body)
    })

    if (!res.ok) {
        if (res.body && typeof res.body.getReader === 'function') {
            const error = await readStreamToJson(res.body)
            if (typeof error === 'string') {
                throw new Error(error)
            }
            throw new Error(error?.error)
        } else {
            throw new Error('An error occurred while processing the request.')
        }
    }

    return res.json()
}

export const commerceClient = async (
    url,
    {body, method = 'POST', headers = {}},
    token
) => {
    const {
        app: {commerceAPI}
    } = getConfig()

    const {
        proxyPath,
        parameters: {siteId}
    } = commerceAPI
    const res = await ssrClient(`${proxyPath}${url}`, {
        method,
        body,
        headers: {
            Authorization: `Bearer ${
                token || localStorage.getItem(`access_token_${siteId}`)
            }`,
            ...headers
        }
    })

    return res
}

/**
 * Subscribes to the newsletter.
 * @param {Object} payload - The payload containing email and phone number.
 * @param {string} payload.email - The email address for the subscription.
 * @param {string} payload.phone - The phone number for the subscription (with country code +1).
 * @returns {Promise<void>} - A promise that resolves when the subscription is successful.
 */
export const newsLetterSubscription = async (payload) => {
    const res = await ssrClient('/newsletter', {
        method: 'POST',
        body: payload
    })

    return res
}

export const resetPassword = async (email, locale) => {
    const res = await ssrClient(`/reset`, {body: {email, locale}})
    return res
}

// set new password on reset password
export const changePassword = async ({newPassword, token, email}) => {
    const res = await ssrClient(`/password/action`, {
        body: {newPassword, token, email}
    })
    return res
}
