import {z} from 'zod'

export const addressFormSchema = z.object({
    firstName: z.string().min(1, 'firstNameRequired'),
    lastName: z.string().min(1, 'lastNameRequired'),
    phone: z.string().min(1, 'phoneNumberRequired'), // Additional validation for phone format can be added
    countryCode: z.enum(['US', 'CA'], 'countryCodeRequired'),
    address1: z.string().min(1, 'address1Required'),
    city: z.string().min(1, 'cityRequired'),
    stateCode: z.string().min(1, 'stateCodeRequired'), // This might need customization based on the country
    postalCode: z.string().min(1, 'postalCodeRequired'), // Additional validation for postal/zip format can be added
    preferred: z.boolean().default(false).optional(),
    addressId: z.string().optional()
})

// Note: For `stateCode`, you might need to dynamically generate the validation schema based on the selected country (US or CA) to enforce the correct options for states or provinces.
// Also, for phone and postalCode, you might want to add more specific validations to match the expected formats.
