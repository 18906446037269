export default {
    baseStyle: {
        box: {
            display: 'flex',
            flexDirection: {base: 'column', lg: 'row'},
            color: 'black'
        },
        contentBox: {
            flexDirection: 'column',
            paddingLeft: {lg: '60px'},
            paddingTop: {lg: '48px'},
            paddingBottom: {lg: '48px'},
            width: {base: '100%', lg: '40%'},
            maxWidth: {lg: '373px'},
            fontSize: '16px',
            fontFamily: `"Roboto" , sans-serif`,
            fontWeight: '300',
            lineHeight: '19px',
            color: 'black',
            minHeight: '282px'
        },
        imageContainer: {
            width: {base: '100%', lg: '60%'},
            borderRadius: {base: '8px', lg: '12px'},
            overflow: 'hidden'
        },

        h6: {
            fontSize: '24px',
            marginBottom: '16px',
            marginTop: {base: '20px', lg: '0'},
            lineHeight: '28px',
            color: 'black',
            fontWeight: '300',
            fontFamily: `"Roboto" , sans-serif`
        },
        link: {
            backgroundColor: 'transparent',
            border: '1px solid',
            borderRadius: '20px',
            fontWeight: '300',
            padding: '8px 12px',
            fontFamily: `"Roboto" , sans-serif`,
            fontSize: 'md',
            textAlign: 'center',
            color: 'black',
            paddingTop: {base: '8px', lg: '6px'},
            paddingBottom: {base: '8px', lg: '6px'},
            display: {base: 'block', lg: 'inline-block'},
            marginTop: {base: '16px', lg: '30px'},
            minWidth: '152px',
            maxWidth: '400px',
            width: {base: '100%', lg: 'fit-content'},
            _hover: {
                textDecoration: 'none'
            }
        },
        text: {
            color: 'black',
            fontWeight: '300',
            fontFamily: `"Roboto" , sans-serif`
        },
        textMargin: {
            marginBottom: {base: '16px', lg: '39px'}
        }
    }
}
