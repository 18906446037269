import {contentfulQueryKeys} from '../../../constants'
import {ingredientsQuery} from '../queries'
import {useContentfulQueryWithHook} from './use-contentful-query.hook'

export const useIngredients = () => {
    const {data, error} = useContentfulQueryWithHook(
        contentfulQueryKeys.ingredients,
        ingredientsQuery
    )

    return {data, error}
}
