import {colors} from '../../../constants'

export default {
    baseStyle: {
        overlay: {
            position: 'fixed',
            width: '100%',
            height: '100%',
            backgroundColor: `${colors.bgOverlay}`,
            left: '0',
            top: '0',
            zIndex: '-1'
        }
    },
    variants: {}
}
