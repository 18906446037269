import React from 'react'

const CurrencyFormatter = ({value, currency}) => {
    // Format currency in the desired format regardless of locale
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'CAD',
        currencyDisplay: 'narrowSymbol' // Use narrow symbol like $ instead of "USD"
    })

    return <span>{formatter.format(value)}</span>
}

export default CurrencyFormatter
