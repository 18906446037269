import {gql} from 'graphql-request'

import {LINK_FRAGMENT, MEDIA_FRAGMENT, THEME_FRAGMENT} from './fragments'

export const getCategoryCollectionQuery = gql`
    ${LINK_FRAGMENT}
    ${MEDIA_FRAGMENT}
    ${THEME_FRAGMENT}
    query CategorySection($locale: String!, $sectionName: String!) {
        contentGridCollection(
            limit: 1
            locale: $locale
            where: {sectionName: $sectionName}
        ) {
            items {
                contentfulTitle
                columns {
                    base
                    sm
                    md
                    lg
                    xl
                }
                itemsCollection(limit: 12) {
                    items {
                        ... on ProductCategory {
                            name
                            theme {
                                ...ThemeFragment
                            }
                            cta {
                                ...LinkFragment
                            }
                            banner {
                                ...MediaFragment
                            }
                        }
                    }
                }
            }
        }
    }
`
