import {getContentGridQuery} from '../queries'
import {useContentfulQueryWithHook} from './use-contentful-query.hook'

export const useCategorySection = (key) => {
    const {data, error} = useContentfulQueryWithHook(key, getContentGridQuery, {
        sectionName: key[0]
    })

    return {data, error}
}
