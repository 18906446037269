import {BLOCKS, INLINES} from '@contentful/rich-text-types'
import {Asset} from './Asset'
import {EmbeddedEntryBlock} from './EmbeddedEntry'
import {HeadingText} from './Header'
import {LinkRenderer} from './LinkRenderer'
import {Paragraph} from './Paragraph'
import React from 'react'
export const options = (links) => {
    // create an asset & entry map
    const assetMap = new Map()
    const entryMap = new Map()
    // loop through the assets and add them to the map
    const linkBlocks = links.entries.inline || []

    const assetBlocks = links.assets.block || []

    for (const asset of assetBlocks) {
        if (asset.typeName.toLowerCase() === 'asset') {
            assetMap.set(asset?.sys?.id, asset)
        }
    }
    // loop through the block linked entries and add them to the map
    for (const entry of linkBlocks) {
        if (entry.typeName.toLowerCase() === 'link') {
            entryMap.set(entry?.sys?.id, entry)
        }
    }

    return {
        renderNode: {
            [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
                return EmbeddedEntryBlock(node, children, entryMap)
            },
            ['embedded-entry-inline']: (node, children) => {
                return EmbeddedEntryBlock(node, children, entryMap)
            },
            [INLINES.HYPERLINK]: LinkRenderer,
            [BLOCKS.PARAGRAPH]: Paragraph,
            [BLOCKS.EMBEDDED_ASSET]: (node) => Asset(node, assetMap),
            [BLOCKS.HEADING_1]: (node) => HeadingText(node, 'h1'),
            [BLOCKS.HEADING_2]: (node) => HeadingText(node, 'h2'),
            [BLOCKS.HEADING_3]: (node) => HeadingText(node, 'h3'),
            [BLOCKS.HEADING_4]: (node) => HeadingText(node, 'h4'),
            [BLOCKS.HEADING_5]: (node) => HeadingText(node, 'h5'),
            [BLOCKS.HEADING_6]: (node) => HeadingText(node, 'h6')
        },
        renderText: (text) =>
            text.split('\n').flatMap((text, i) => [i > 0 && <br />, text])
    }
}
