export const languageAliases = {
    en: {
        path: '/us',
        locale: 'en-CA',
        localeName: 'en'
    },
    fr: {
        path: '/fr',
        locale: 'fr-CA',
        localeName: 'fr'
    }
}
