import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'

import {
    Divider,
    Flex
} from '@salesforce/retail-react-app/app/components/shared/ui'
import useMultiSite from '@salesforce/retail-react-app/app/hooks/use-multi-site'
import Link from '../../../components/link'
import {colors, languageAliases} from '../../../constants'
import {getPathWithLocale} from '../../../utils/url'
import './LanguageSelector.scss'

export const LanguageSelector = ({parentClasses, parentStyles}) => {
    const location = useLocation()
    const [selectedLocale, setSelectedLocale] = useState(
        languageAliases.en.localeName
    )

    const {buildUrl} = useMultiSite()

    const switchLocale = (locale) => {
        localStorage?.setItem('locale', languageAliases[locale].locale)
        const newUrl = getPathWithLocale(
            languageAliases[locale.toLowerCase()].locale,
            buildUrl,
            {
                disallowParams: ['refine']
            }
        )
        window.location = newUrl
        setSelectedLocale(locale)
    }

    useEffect(() => {
        if (location.pathname.match(languageAliases.fr.path)) {
            setSelectedLocale(languageAliases.fr.localeName)
        }
    }, [location])

    return (
        <Flex
            gap="8px"
            alignItems={{base: 'flex-end', xl: 'center'}}
            // marginTop="24px"
            flexDirection={{base: 'column', xl: 'row'}}
            fontFamily="Roboto"
            height={{xl: '20px'}}
            marginLeft={{xl: '24px'}}
            marginRight={{xl: '0px'}}
        >
            <Link
                type="tertiary"
                onLinkClick={() => switchLocale(languageAliases.en.localeName)}
                buttonStyles={{
                    padding: '10px',
                    width: '26px',
                    height: '26px',
                    minWidth: '26px',
                    minHeight: '26px',
                    textTransform: 'uppercase',
                    font: 'Roboto',
                    color:
                        languageAliases.en.localeName ===
                        selectedLocale.toLowerCase()
                            ? colors.black
                            : colors.quilGray,
                    fontWeight: '300',
                    fontSize: '16px',
                    backgroundColor: 'none',
                    background: 'none',
                    border: 'none',
                    _hover: {
                        background: 'none',
                        backgroundColor: 'none'
                    }
                }}
                inactive={
                    languageAliases.en.localeName !==
                    selectedLocale.toLowerCase()
                }
                text="en"
            />
            <Divider
                display={{base: 'none', xl: 'block'}}
                orientation="vertical"
                height="16px"
                fontWeight="400"
                color="black"
                border="1px solid black"
            />
            <Link
                type="tertiary"
                onLinkClick={() => switchLocale(languageAliases.fr.localeName)}
                buttonStyles={{
                    padding: '10px',
                    width: '26px',
                    height: '26px',
                    minWidth: '26px',
                    minHeight: '26px',
                    textTransform: 'uppercase',
                    font: 'Roboto',
                    color:
                        languageAliases.fr.localeName ===
                        selectedLocale.toLowerCase()
                            ? colors.black
                            : colors.quilGray,
                    fontWeight: '300',
                    fontSize: '16px',
                    background: 'none',
                    backgroundColor: 'none',
                    border: 'none',
                    _hover: {
                        background: 'none',
                        backgroundColor: 'none'
                    }
                }}
                inactive={
                    languageAliases.fr.localeName !==
                    selectedLocale.toLowerCase()
                }
                text="fr"
            />
        </Flex>
    )
}

LanguageSelector.propTypes = {
    parentClasses: PropTypes.string,
    parentStyles: PropTypes.object
}
