import {contentfulQueryKeys} from '../../../constants'
import {getFooterQuery} from '../queries'
import {useContentfulQueryWithHook} from './use-contentful-query.hook'

export const useFooterData = () => {
    const {data, error} = useContentfulQueryWithHook(
        contentfulQueryKeys.getFooterQuery,
        getFooterQuery
    )

    return {data, error}
}
