import {useMutation} from '@tanstack/react-query'
import {useIntl} from 'react-intl'
import {newsLetterSubscription} from '..'

export const useNewsletterSubscribeMutation = () => {
    const {locale} = useIntl()
    return useMutation(async (payload) => {
        return newsLetterSubscription({...payload, locale})
    })
}
