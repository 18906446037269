const colors = {
    quilGray: '#F7F7F7',
    light: '#F2F2F2',
    platinum: '#E6E6E6'
}
export default {
    baseStyle: {
        container: {
            width: 'full',
            backgroundColor: colors.quilGray,
            padding: {base: '31px 47px'}
        },
        footerSectionContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        subscribe: {
            display: 'flex',
            alignItems: 'center',
            gap: '32px',
            border: '1px solid red'
        },
        subscribeHeading: {
            fontSize: '24px',
            lineHeight: '30px',
            fontWeight: 'regular'
        },
        subscribeInputBox: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0'
        },
        subscribeField: {
            width: '100%',
            maxWidth: '471px',
            background: 'white',
            border: '1px solid #E6E6E6',
            borderRadius: '33px',
            margin: 0
        },
        roundedBtn: {
            backgroundColor: `${colors.light}`,
            color: 'black',
            // padding: '6px 26px',
            borderRadius: '34px',
            display: {base: 'none', lg: 'block'},
            fontWeight: '300',
            _hover: {
                backgroundColor: `${colors.light}`,
                textDecoration: 'none'
            }
        },
        divider: {
            backgroundColor: `${colors.platinum}`,
            height: '44px',
            marginLeft: '30px',
            marginRight: '30px',
            width: '1px',
            display: {base: 'none', lg: 'block'}
        },
        footerImageContainer: {
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'flex-end'
        },
        viewAllButton: {
            fontWeight: '300',
            fontSize: '16px',
            color: 'black',
            textDecoration: 'underline'
        }
    },
    parts: ['container', 'content', 'bodyContainer', 'logo', 'icons', 'signout']
}
