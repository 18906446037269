import {colors} from '../../../constants'

export default {
    baseStyle: {
        CHIP: {
            width: '26px',
            height: '4px',
            margin: '0 6px',
            borderRadius: '3px',
            backgroundColor: 'gray',
            cursor: 'pointer'
        }
    },
    variants: {
        circle: (props) => ({
            chip: {
                width: props.selected ? '14px' : '7px',
                height: '7px',
                backgroundColor: props.selected ? '#000000' : '#E3E3E3',
                borderRadius: props.selected ? '36px' : '100%'
            },
            chipContainer: {
                justifyContent: {base: 'center', lg: 'flex-end'},
                gap: '4px'
            }
        }),
        flat: (props) => ({
            chip: {
                width: '26px',
                height: '4px',
                margin: '0 6px',
                borderRadius: '3px',
                backgroundColor: props.selected
                    ? 'black'
                    : props.chipColor
                    ? props.chipColor
                    : colors.chipGray,
                cursor: 'pointer'
            },
            chipContainer: {
                justifyContent: {base: 'center', lg: 'flex-end'}
            }
        })
    }
}
