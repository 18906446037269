import {colors} from '../../../constants'

export default {
    baseStyle: {
        inputGroup: {
            position: 'relative'
        },
        searchGroup: {
            width: {base: '100%', xl: '0'},
            maxWidth: {'2xl': '496px'},
            position: {base: 'fixed', xl: 'static'},
            order: {base: '2', xl: 'unset'},
            left: 0,
            paddingTop: {base: '31px', xl: '0'},
            paddingBottom: {base: '5px', xl: '0'},
            paddingLeft: {base: '5px', xl: '0'},
            paddingRight: {base: '5px', xl: '0'},
            background: 'white',
            transition: 'all 0.4s ease-in',
            transform: {base: 'translateY(-71px)', xl: 'unset'},
            opacity: 0,
            zIndex: -1,
            boxShadow: {base: 'base', xl: 'unset'}
        },
        searchBtn: {
            backgroundColor: 'black',
            borderRadius: '24px',
            width: '80px',
            fontSize: '14px',
            fontWeight: '300',
            height: '26px',
            right: '5px',
            top: '50%',
            transform: 'translateY(-50%)',
            display: {xl: 'none'},
            position: 'absolute',
            _hover: {
                backgroundColor: 'black'
            }
        },
        searchSuggestionDesk: {
            position: 'fixed',
            width: '73%',
            right: '0',
            top: '67px'
        },
        desktopSearchContainer: {
            width: '259px',
            backgroundColor: `${colors.lightGrayish}`,
            padding: '34px 16px'
        },
        suggestionRight: {
            bg: 'white',
            width: 'calc(100% - 259px)',
            paddingLeft: '40px',
            paddingTop: '40px',
            paddingRight: '40px',
            paddingBottom: '27px'
        },
        productWrapper: {
            fontSize: '24px',
            fontWeight: '300',
            marginBottom: '25px',
            fontFamily: `'Raleway', sans-serif`
        },
        closeIcon: {
            position: 'absolute',
            right: '40px',
            top: '25px',
            cursor: 'pointer'
        },
        productContainer: {
            marginBottom: '58px',
            justifyContent: 'space-between',
            gap: '30px'
        },
        viewAll: {
            padding: '10px 58px',
            border: '1px solid black',
            borderRadius: '20px'
        },
        suggestionText: {
            fontSize: '16px',
            fontWeight: '300',
            color: `${colors.textLight}`,
            marginBottom: {base: '15px', xl: '12px'},
            fontFamily: `'Raleway', sans-serif`,
            sx: {b: {fontWeight: '300'}},
            textAlign: 'left'
        },
        suggestionHead: {
            fontSize: '20px',
            lineHeight: '24px',
            fontWeight: '300',
            color: 'black',
            fontFamily: `'Roboto', sans-serif`,
            marginBottom: '15px'
        }
    }
}
