import {gql} from 'graphql-request'

export const IMAGE_VIDEO_ASSET_FRAGMENT = gql`
    fragment ImageVideoAssetFragment on Asset {
        typeName: __typename
        title
        description
        url
        fileName
        contentType
        sys {
            id
        }
    }
`
