import {gql} from 'graphql-request'

import {LINK_FRAGMENT} from './fragments'

export const getNewsletterQuery = gql`
    ${LINK_FRAGMENT}

    query NewsletterModal($locale: String!) {
        modalCollection(
            limit: 1
            locale: $locale
            where: {name: "newsletter"}
        ) {
            items {
                contentfulTitle
                media {
                    desktop {
                        url
                    }
                }
                mediaAlignment
                heading
                headingType
                theme {
                    backgroundColor
                    color
                }
                body
                content {
                    ... on Form {
                        successMessage
                        errorMessage
                        afterSuccessLink {
                            ... on Link {
                                ...LinkFragment
                            }
                        }
                        submit {
                            ... on Link {
                                ...LinkFragment
                            }
                        }
                        name
                        fieldsCollection {
                            items {
                                ... on Input {
                                    placeholder
                                    errorMessage
                                    type
                                    required
                                    label
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
