export default {
    baseStyle: {
        videoContainer: {
            width: '100%',
            overflow: 'hidden',
            position: 'relative'
        },
        content: {
            display: 'flex',
            margin: {base: '29px 17px', lg: '45px 52px'},
            gap: {base: '12px', lg: '32px'},
            alignItems: 'center'
        },
        audioIcon: {
            width: {base: '29px', lg: '57px'},
            height: {base: '25px', lg: '49px'}
        },
        videoTitle: {
            fontWeight: 300,
            fontSize: {base: '16px', lg: '24px'},
            lineHeight: {base: '18.78px', lg: '28px'},
            color: '#FFFFFF'
        }
    }
}
