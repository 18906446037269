import {z} from 'zod'
import {validatePassword} from '../utils/password-utils'
import {formatPhoneNumber} from '@salesforce/retail-react-app/app/utils/phone-utils'

export const userProfileSchema = z.object({
    firstName: z.string().min(1, {
        message: 'use_profile_fields.error.required_first_name'
    }),
    lastName: z.string().min(1, {
        message: 'use_profile_fields.error.required_last_name'
    }),
    email: z.string().email({
        message: 'use_profile_fields.error.required_email'
    }),
    currentPassword: z.string().min(1, {
        message: 'use_update_password_fields.error.required_password'
    }),
    password: z
        .string()
        .refine((val) => !val || validatePassword(val).hasMinChars, {
            message: 'use_update_password_fields.error.minimum_characters'
        })
        .refine((val) => !val || validatePassword(val).hasUppercase, {
            message: 'use_update_password_fields.error.uppercase_letter'
        })
        .refine((val) => !val || validatePassword(val).hasLowercase, {
            message: 'use_update_password_fields.error.lowercase_letter'
        })
        .refine((val) => !val || validatePassword(val).hasNumber, {
            message: 'use_update_password_fields.error.contain_number'
        })
        .refine((val) => !val || validatePassword(val).hasSpecialChar, {
            message: 'use_update_password_fields.error.special_character'
        })
})

export const createAccountSchema = z.object({
    firstName: z
        .string({
            message: 'use_registration_fields.error.required_email'
        })
        .min(1, {
            message: 'use_registration_fields.error.required_first_name'
        }),
    lastName: z
        .string({
            message: 'use_registration_fields.error.required_email'
        })
        .min(1, {
            message: 'use_registration_fields.error.required_last_name'
        }),
    email: z
        .string({
            message: 'use_registration_fields.error.required_email'
        })
        .email({
            message: 'use_registration_fields.error.required_email'
        }),
    password: z
        .string()
        .refine((val) => val && validatePassword(val).hasMinChars, {
            message: 'use_registration_fields.error.minimum_characters'
        })
        .refine((val) => val && validatePassword(val).hasUppercase, {
            message: 'use_registration_fields.error.uppercase_letter'
        })
        .refine((val) => val && validatePassword(val).hasLowercase, {
            message: 'use_registration_fields.error.lowercase_letter'
        })
        .refine((val) => val && validatePassword(val).hasNumber, {
            message: 'use_registration_fields.error.contain_number'
        })
        .refine((val) => val && validatePassword(val).hasSpecialChar, {
            message: 'use_registration_fields.error.special_character'
        })
        .refine((val) => val && validatePassword(val).hasNoSpace, {
            message: 'use_registration_fields.error.has_no_space'
        }),
    province: z
        .string({
            message:
                'use_address_fields.error.please_select_your_state_or_province'
        })
        .min(1, {
            message:
                'use_address_fields.error.please_select_your_state_or_province'
        }),
    mobileNumber: z
        .string()
        .optional()
        .refine(
            (value) => {
                if (!value) {
                    return true
                } // Allow undefined or null
                return value.length === 14
            },
            {
                message: 'Phone number should be 10 digits long'
            }
        ),
    acceptsMarketing: z.boolean().refine((value) => value === true, {
        message: 'Need to agree to the conditions.'
    }),
    agreeToUseOfInformation: z.boolean().refine((value) => value === true, {
        message: 'Need to agree to the conditions.'
    }),
    agreeToTerms: z.boolean().refine((value) => value === true, {
        message: 'Need to agree to the conditions.'
    })
})

export const storeCreateAccountSchema = createAccountSchema.omit({
    password: true
})
