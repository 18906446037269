import {
    Box,
    Flex,
    Icon,
    useToast
} from '@salesforce/retail-react-app/app/components/shared/ui'
import React from 'react'
import {
    CheckCircleGreenIcon,
    WarningIcon,
    XCircleRedIcon
} from '../components/icons'
import Link from '../components/link'
import {colors} from '../constants'

const toastStatusColors = {
    success: 'green',
    info: 'black',
    warning: 'black',
    error: 'red'
}

export const toastStatuses = {
    success: 'success',
    error: 'error',
    info: 'info',
    warning: 'warning'
}

export const statusIcons = {
    success: CheckCircleGreenIcon,
    error: XCircleRedIcon,
    warning: WarningIcon
}

export const useStyledToast = () => {
    const toast = useToast()
    const showToast = ({
        status = 'info',
        icon,
        duration = 5000,
        isClosable = false,
        position = 'top-right',
        message,
        ...props
    }) => {
        toast.closeAll()
        toast({
            ...props,
            status,
            duration,
            isClosable,
            position,
            render: () => (
                <Box
                    color={toastStatusColors[status]}
                    px="24px"
                    py="12px"
                    border="1px solid"
                    fontWeight="400"
                    background={'white'}
                    borderColor="#0000001F"
                    borderRadius="8px"
                    boxShadow="box-shadow: 0px 2px 2px 0px #0000000D;"
                >
                    <Flex gap="12px">
                        {icon ||
                            (status !== 'info' && (
                                <Icon as={statusIcons[status]} />
                            ))}
                        {message}
                    </Flex>
                </Box>
            )
        })
    }

    const showLinkToast = ({
        status = 'info',
        duration = 20000,
        icon,
        isClosable = false,
        position = 'top-right',
        message,
        linkText,
        linkUrl,
        ...props
    }) => {
        toast.closeAll()
        toast({
            ...props,
            status,
            duration,
            isClosable,
            position,
            render: () => (
                <Box
                    color={toastStatusColors[status]}
                    p={3}
                    border="1px solid"
                    fontWeight="400"
                    background={'white'}
                    borderColor={colors.grey[300]}
                    // borderRadius={borderSchemes.medium}
                    boxShadow="0px 2px 2px 0px #0000000D"
                    minWidth="320px"
                >
                    <Flex gap="24" justifyContent="space-between">
                        <Flex gap="12">
                            {icon ||
                                (status !== 'info' && (
                                    <Icon as={statusIcons[status]} />
                                ))}
                            {message}
                        </Flex>
                        <Link
                            url={linkUrl}
                            buttonStyles={{
                                //styleName: Text/Body Bold;
                                fontWeight: '700',
                                textAlign: 'left',
                                color: colors.blueCol,
                                textDecoration: 'none'
                            }}
                        >
                            {linkText}
                        </Link>
                    </Flex>
                </Box>
            )
        })
    }

    return {showToast, showLinkToast}
}
