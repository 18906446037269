export default {
    baseStyle: {
        tile: {
            color: 'black',
            fontFamily: `'Raleway', sans-serif`
        },
        imgContainer: {
            marginBottom: {base: '11px', xl: '20px'}
        },
        name: {
            marginBottom: {base: '4px', xl: '8px'},
            fontSize: {base: '14px', xl: '20px'},
            fontWeight: '300',
            lineHeight: {xl: '25px'}
        },
        price: {
            fontFamily: `'Roboto', sans-serif`,
            p: {
                fontSize: {base: '10px', xl: '16px'}
            }
        },
        priceSx: {
            '.chakra-text': {
                fontSize: {base: '10px', xl: '16px'}
            }
        },
        brand: {
            fontSize: {base: '10px', xl: '14px'},
            marginBottom: {base: '4px', xl: '8px'},
            fontFamily: `'Roboto', sans-serif`
        }
    }
}
