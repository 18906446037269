import {getBooxiApiKey} from '../utils/site-utils'
import {useIntl} from 'react-intl'
import _ from 'lodash'
import {languageAliases} from '../constants'

const onClient = typeof window !== 'undefined'

export const useBooxi = () => {
    const booxiApiKey = getBooxiApiKey()
    const {locale} = useIntl()

    const language = _.find(languageAliases, (lang) => lang.locale === locale)

    const instantiateBooxi = (serviceId) => {
        if (serviceId) {
            !!onClient &&
                BookNow.open({
                    apiKey: booxiApiKey,
                    serviceId,
                    mode: 'iframe',
                    language: language.localeName,
                    fallbackLanguage: 'en',
                    iframeId: 'booxi'
                })
        } else {
            !!onClient &&
                BookNow.open({
                    apiKey: booxiApiKey,
                    mode: 'iframe',
                    language: language.localeName,
                    fallbackLanguage: 'en',
                    iframeId: 'booxi'
                })
        }
    }

    return {instantiateBooxi}
}
