import React from 'react'
import Link from '../link'
import {colors} from '../../constants'

export const EmbeddedEntryBlock = (node, children, entryMap) => {
    if (!entryMap || entryMap.size === 0) {
        return <></>
    }

    const entry = entryMap ? entryMap.get(node.data.target.sys.id) : {}
    const embeddedContentType =
        entry && entry?.typeName.toString().toLowerCase()

    switch (embeddedContentType) {
        case 'link': {
            return (
                <Link
                    href={entry?.url}
                    url={entry?.url}
                    type="link"
                    isExternal
                    externalUrl={entry.externalUrl || entry.url}
                    text={entry.text}
                    iconName={entry.iconName}
                    buttonStyles={{
                        background: colors.white,
                        backgroundColor: colors.white,
                        marginRight: '20px'
                    }}
                    {...(entry?.url.includes('http')
                        ? {
                              target: '_blank',
                              rel: 'noopener noreferrer'
                          }
                        : {})}
                />
            )
        }
        default:
            console.warn(
                `Unexpected embedded block type "${embeddedContentType}"`
            )
            return <></>
    }
}
