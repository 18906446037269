import PropTypes from 'prop-types'
import React from 'react'

import {
    Box,
    Divider,
    Flex,
    Text,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {styleConfigChakraKeys} from '../../constants'
import Link from '../link'

const FooterMeta = ({variant, copyright, socialMediaIconsCollectionItems}) => {
    const styles = useMultiStyleConfig(styleConfigChakraKeys.footer, {variant})

    return (
        <Flex {...styles.footerMeta} id="footer-meta">
            <Text {...styles.copyright}>{copyright}</Text>
            <Divider {...styles.divider} display={{lg: 'none'}} />

            {socialMediaIconsCollectionItems &&
                socialMediaIconsCollectionItems.length > 0 && (
                    <Box {...styles.socialIcons}>
                        {socialMediaIconsCollectionItems.map((item, index) => (
                            <Link
                                {...item}
                                type="tertiary"
                                key={index}
                                iconStyles={{width: '24px', height: '24px'}}
                            />
                        ))}
                    </Box>
                )}
        </Flex>
    )
}

FooterMeta.propTypes = {
    copyright: PropTypes.string,
    socialMediaIconsCollectionItems: PropTypes.arrayOf(
        PropTypes.shape({
            iconName: PropTypes.string,
            type: PropTypes.number,
            text: PropTypes.string,
            url: PropTypes.string,
            isExternal: PropTypes.bool
        })
    ),
    variant: PropTypes.string
}

export default FooterMeta
