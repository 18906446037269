import {colors} from '../../../constants'

export default {
    baseStyle: {
        primary: {
            padding: '8px 14px',
            display: 'flex',
            background: colors.btnGradient,
            backgroundColor: colors.light,
            color: colors.textLight,
            borderRadius: '34px',
            fontWeight: '300',
            _hover: {
                background: colors.btnGradient,
                textDecoration: 'none'
            },
            minWidth: {base: '163px', lg: '122px'},
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px'
        },
        secondary: {
            padding: '8px 14px',
            display: 'flex',
            background: 'none',
            color: '#FFFFFF',
            border: '1px solid #FFFFFF',
            borderRadius: '20px',
            fontWeight: '300',
            _hover: {
                background: 'none',
                textDecoration: 'none'
            },
            minWidth: {base: '152px', lg: '122px'},
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px'
        },
        tertiary: {
            fontSize: '16px',
            lineHeight: '18.75px',
            fontWeight: '300',
            backgroundColor: 'transparent',
            padding: '0px'
        },
        inactive: {
            opacity: '40%'
        }
    },
    parts: []
}
