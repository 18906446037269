import PropTypes from 'prop-types'
import React, {useRef} from 'react'

import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalContent,
    Text,
    // Hooks
    useDisclosure,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import {colors, styleConfigChakraKeys} from '../../constants'
import {LeftChevronIcon, RightChevronIcon} from '../icons'
import Link from '../link'
import {FormattedMessage} from 'react-intl'

function ChildModal(props) {
    const {items, item, name, isNested, ivalue, closeDrawer, ...rest} = props
    const btnRef = useRef()
    const {isOpen, onOpen, onClose} = useDisclosure()
    const styles = useMultiStyleConfig(styleConfigChakraKeys.drawerMenu)
    return (
        <Box>
            {/* Heading */}
            <Box>
                {items?.length > 0 ? (
                    <Button
                        ref={btnRef}
                        onClick={() => {
                            if (item.isDisabled) {
                                return
                            }
                            onOpen()
                        }}
                        {...styles.mobileNavLink}
                        marginLeft={isNested ? 'unset' : 'auto'}
                        textAlign={isNested ? 'left' : 'right'}
                        maxWidth="unset"
                        style={{'--index': ivalue}}
                        sx={{'-webkit-tap-highlight-color': 'transparent'}}
                        {...(item.isDisabled ? {pointerEvents: 'none'} : {})}
                        color={item.isDisabled ? colors.grayish : colors.black}
                    >
                        <Text paddingRight="2px">{name}</Text>
                        {!item.isDisabled && (
                            <RightChevronIcon
                                {...styles.chevronIcon}
                                color="#000000"
                            />
                        )}
                    </Button>
                ) : (
                    <Link
                        {...item}
                        {...styles.mobileNavLink}
                        marginLeft={isNested ? 'unset' : 'auto'}
                        textAlign={isNested ? 'left' : 'right'}
                        style={{
                            '--index': ivalue,
                            color: item.isDisabled ? colors.grayish : '#000000'
                        }}
                        {...(item.isDisabled ? {pointerEvents: 'none'} : {})}
                        onLinkClick={() => {
                            if (item.isDisabled) {
                                return
                            }
                            closeDrawer()
                        }}
                        onClick={() => {
                            if (item.isDisabled) {
                                return
                            }
                            closeDrawer()
                        }}
                        sx={{'-webkit-tap-highlight-color': 'transparent'}}
                    />
                )}
            </Box>

            {/* Child Items */}
            {items?.length > 0 && (
                <Modal
                    isOpen={isOpen}
                    onClose={onClose}
                    finalFocusRef={btnRef}
                    size={'full'}
                    variant={'megaNavModal'}
                >
                    <ModalContent {...styles.modalContent}>
                        <ModalBody
                            display="flex"
                            {...styles.modalBody}
                            paddingBottom="41px !important"
                        >
                            <Box marginLeft="auto" marginRight="auto">
                                <Button
                                    {...styles.mobileNavLink}
                                    paddingLeft="0"
                                    fontWeight="500"
                                    marginLeft="unset"
                                    onClick={onClose}
                                    style={{'--index': -1}}
                                    sx={{
                                        '-webkit-tap-highlight-color':
                                            'transparent'
                                    }}
                                >
                                    <LeftChevronIcon
                                        {...styles.chevronIcon}
                                        marginRight="4px"
                                    />{' '}
                                    <Text>
                                        <FormattedMessage
                                            defaultMessage="Back"
                                            id="drawer_menu.button.back"
                                        />
                                    </Text>
                                </Button>
                                <NestedMobileMenu
                                    {...rest}
                                    items={items}
                                    isNested={true}
                                    isNavOpen={isOpen}
                                    closeDrawer={closeDrawer}
                                />
                            </Box>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}
        </Box>
    )
}

ChildModal.propTypes = {
    items: PropTypes.array,
    item: PropTypes.object,
    name: PropTypes.string,
    isNested: PropTypes.bool,
    isNavOpen: PropTypes.bool,
    ivalue: PropTypes.number,
    closeDrawer: PropTypes.func
}

function NestedMobileMenu(props) {
    const {isNested = false, isNavOpen, items, closeDrawer} = props

    return (
        <>
            {items.map((item, iV) => {
                const {heading} = item
                const subItems = item?.itemsCollection?.items

                return (
                    <ChildModal
                        items={subItems}
                        name={heading}
                        item={item}
                        isNested={isNested}
                        isNavOpen={isNavOpen}
                        ivalue={iV}
                        key={item?.sys?.id}
                        closeDrawer={closeDrawer}
                    />
                )
            })}
        </>
    )
}

NestedMobileMenu.propTypes = {
    isNested: PropTypes.bool,
    isNavOpen: PropTypes.bool,
    items: PropTypes.array,
    closeDrawer: PropTypes.func
}

export default NestedMobileMenu
