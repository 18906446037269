import {Heading} from '@salesforce/retail-react-app/app/components/shared/ui'
import React from 'react'
import {v4 as uuidv4} from 'uuid'

export const HeadingText = (node, type) => {
    switch (type) {
        case 'h1':
            return node.content[0].nodeType === 'text' ? (
                <Heading as="h1" className="h1" key={uuidv4()}>
                    {node.content[0]?.value}
                </Heading>
            ) : (
                <></>
            )
        case 'h2':
            return node.content[0].nodeType === 'text' ? (
                <Heading as="h2" className="h2" key={uuidv4()}>
                    {node.content[0]?.value}
                </Heading>
            ) : (
                <></>
            )
        case 'h3':
            return node.content[0].nodeType === 'text' ? (
                <Heading
                    variant="h3"
                    size="md"
                    key={uuidv4()}
                    as="h3"
                    className="h3"
                >
                    {node.content[0]?.value}
                </Heading>
            ) : (
                <></>
            )
        case 'h4':
            return node.content[0].nodeType === 'text' ? (
                <h4 key={uuidv4()}>{node.content[0]?.value}</h4>
            ) : (
                <></>
            )
        case 'h5':
            return node.content[0].nodeType === 'text' ? (
                <h5 key={uuidv4()}>{node.content[0]?.value}</h5>
            ) : (
                <></>
            )
        case 'h6':
            return node.content[0].nodeType === 'text' ? (
                <h6 key={uuidv4()}>{node.content[0]?.value}</h6>
            ) : (
                <></>
            )
    }
}
