// override headings from chakra theme

export default {
    variants: {
        h1: {
            fontSize: '56px'
        },
        h2: {
            fontSize: '48px'
        },
        h3: {
            fontSize: '40px'
        },
        h4: {
            fontSize: '32px'
        },
        h5: {
            fontSize: '24px'
        },
        h6: {
            fontSize: '20px'
        }
    }
}
