import {contentfulQueryKeys} from '../../../constants'
import {getstaticTemplatePageCollectionQuery} from '../queries'
import {useContentfulQueryWithHook} from './use-contentful-query.hook'

export const useStaticTemplatePage = (slug) => {
    const {data, error} = useContentfulQueryWithHook(
        [...contentfulQueryKeys.staticTemplatePage, slug],
        getstaticTemplatePageCollectionQuery,
        {
            slug
        }
    )
    return {data, error}
}
