import {colors} from '../../../constants'

export default {
    baseStyle: {
        pageContainer: {
            width: '100vw',
            backgroundColor: {
                base: `${colors.bgSilverGray1}`,
                xl: `${colors.bgSilverGray}`
            }
        },
        container: {
            maxWidth: '1920px',
            width: '100%',
            margin: 'auto'
        },
        tabsContainer: {
            paddingLeft: {base: '16px', xl: '36px'},
            paddingRight: {base: '16px', xl: '36px'},
            paddingTop: {base: '16px', xl: '71px'},
            paddingBottom: {base: '16px', xl: '71px'},
            marginBottom: {base: '20px', xl: '0'},
            position: 'relative'
        },
        tabsBtnContainer: {
            marginBottom: {base: '32px', xl: '42px'}
        },
        tabsBtn: {
            fontSize: {base: '24px', xl: '32px'},
            lineHeight: {base: '28px', xl: '38px'},
            fontWeight: '400',
            fontFamily: `'Raleway', sans-serif`,
            padding: '0',
            textTransform: 'capitalize',
            marginRight: '30px',
            color: {base: `${colors.grayish}`, xl: `${colors.cloudy}`}
        },
        rightCta: {
            position: 'absolute',
            top: '71px',
            right: '36px',
            display: {base: 'none', xl: 'block'}
        }
    }
}
