import {contentfulQueryKeys} from '../../../constants'
import {getRealmBlogPageQuery} from '../queries'
import {useContentfulQueryWithHook} from './use-contentful-query.hook'

/* 
    hooks for fetching Realm Page
*/

export const useRealmBlogPage = () => {
    const {data, error} = useContentfulQueryWithHook(
        contentfulQueryKeys.getRealmBlogPageQuery,
        getRealmBlogPageQuery
    )
    return {data, error}
}
