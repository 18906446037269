import {
    Box,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import PropTypes from 'prop-types'
import React from 'react'
import {styleConfigChakraKeys} from '../../../constants'
import burgerAnimation from '../../../theme/animations/hamburger-animation'
import {CloseIcon} from '../../icons'
import {BoxAnimated} from './box-animated'

const BurgerAnimation = ({isActive, enableAnimation}) => {
    const styles = useMultiStyleConfig(styleConfigChakraKeys.header)

    if (isActive) {
        return (
            <CloseIcon
                animation={`${burgerAnimation.closeIcon} 0.3s ease-in-out forwards`}
            />
        )
    }

    return (
        <>
            <Box {...styles.burgerAnim}>
                <BoxAnimated
                    as="span"
                    {...styles.burgerSpan}
                    transform="rotate(0)"
                    opacity="1"
                    animationName={burgerAnimation.rotateFrom45ToZeroAnimation}
                    enableAnimation={enableAnimation}
                ></BoxAnimated>
                <BoxAnimated
                    as="span"
                    {...styles.burgerSpan}
                    transform="rotate(0)"
                    opacity="0"
                    animationName={
                        burgerAnimation.rotateFromMinus45ToZeroAnimation
                    }
                    enableAnimation={enableAnimation}
                ></BoxAnimated>
                <BoxAnimated
                    as="span"
                    {...styles.burgerSpan}
                    transform="translateY(9px)"
                    opacity="1"
                    animationName={burgerAnimation.scaleToTopAnimation}
                    enableAnimation={enableAnimation}
                ></BoxAnimated>
                <BoxAnimated
                    as="span"
                    {...styles.burgerSpan}
                    transform="translateY(-9px)"
                    opacity="1"
                    animationName={burgerAnimation.scaleToBottomAnimation}
                    enableAnimation={enableAnimation}
                ></BoxAnimated>
            </Box>
        </>
    )
}

BurgerAnimation.propTypes = {
    enableAnimation: PropTypes.bool,
    isActive: PropTypes.bool
}

export default BurgerAnimation
