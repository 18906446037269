import {gql} from 'graphql-request'
import {LINK_FRAGMENT, MEDIA_FRAGMENT, THEME_FRAGMENT} from './fragments'

export const getCarouselCollectionQuery = gql`
    ${LINK_FRAGMENT}
    ${MEDIA_FRAGMENT}
    ${THEME_FRAGMENT}
    query CarouselSection($locale: String!, $sectionName: String!) {
        carouselCollection(
            limit: 1
            locale: $locale
            where: {sectionName: $sectionName}
        ) {
            items {
                heading
                headingType
                description
                cta {
                    ...LinkFragment
                }
                fixedWidth
                theme {
                    ...ThemeFragment
                }
                itemsCollection(limit: 10) {
                    items {
                        ... on ServiceCard {
                            image {
                                ...MediaFragment
                            }
                            name
                            heading
                            headingType
                            currency
                            price
                            time
                            description
                            serviceId
                            cta {
                                ...LinkFragment
                            }
                        }
                        ... on Product {
                            contentfulTitle
                            name
                            description
                            regularPrice
                            salePrice
                            brand {
                                ... on Brand {
                                    name
                                }
                            }
                            image {
                                ...MediaFragment
                            }
                            productId
                            currency
                        }

                        ... on Article {
                            cardTitle
                            cardImage {
                                ...MediaFragment
                            }
                            cardCta {
                                ...LinkFragment
                            }
                        }
                    }
                }
            }
        }
    }
`
