import {colors} from '../../../constants/colors'

export default {
    baseStyle: {
        container: {
            alignItems: 'center',
            flexDirection: {base: 'column-reverse', lg: 'row'},
            justifyContent: {base: 'flex-start', lg: 'space-between'},
            gap: {base: '20px', lg: '69px'},
            w: '100%'
        },
        ctaSectionContainer: {
            minWidth: {base: '343px', md: '430px', lg: '450px', xl: '530px'},
            gap: {base: '40px', lg: '30px'}
        },
        heroImageContainer: {
            maxHeight: '729px',
            width: '100%',
            maxWidth: {base: '1057px', xl: '100%'},
            height: {
                base: '286px',
                sm: '340px',
                md: '400px',
                lg: '600px',
                xl: '729px'
            },
            borderRadius: '12px',
            overflow: 'hidden',
            div: {
                video: {
                    objectFit: 'cover'
                }
            }
        },
        button: {
            marginTop: '18px',
            width: '100%',
            maxWidth: ['100%', '100%', '192px'],
            justifyContent: 'space-between',
            height: 'auto',
            background: colors.mistGray,
            paddingY: ['14px', '14px', '10px', '15px'],
            borderRadius: '8px',
            border: '1px solid rgba(174, 174, 174, 0.37)',
            fontSize: '16px',
            fontWeight: '300',
            fontFamily: 'Roboto',
            color: colors.black,
            _hover: {
                background: colors.chipGray,
                border: '1px solid rgba(174, 174, 174, 1)'
            }
        },
        bannerButton: {
            marginTop: '18px',
            width: '100%',
            maxWidth: ['100%', '100%', '500px'],
            justifyContent: 'space-between',
            height: 'auto',
            background: colors.mistGray,
            paddingY: ['4px', '4px', '4px', '19px'],
            borderRadius: '8px',
            border: '1px solid rgba(174, 174, 174, 0.37)',
            fontSize: ['16px', '16px', '24px', '24px'],
            fontWeight: '300',
            fontFamily: 'Roboto',
            color: colors.black,
            _hover: {
                background: colors.chipGray,
                border: '1px solid rgba(174, 174, 174, 1)'
            }
        },
        iconStyleCard: {
            opacity: '0',
            _hover: {opacity: '1'}
        },
        iconStyleBanner: {
            opacity: '0',
            _hover: {opacity: '1'},
            boxSize: '47px'
        }
    }
}
