import {z} from 'zod'

export const newsletterSchema = z.object({
    email: z.string().email({message: 'Invalid email address'}),
    phone: z
        .string()
        .optional()
        .refine(
            (phone) => {
                if (!phone) {
                    return true
                }
                const pattern =
                    /^(\+?1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
                return pattern.test(phone)
            },
            {
                message:
                    'Invalid US/Canada phone number. It should start with +1 and followed by 10 digits.'
            }
        )
})
