import {Drawer} from '@salesforce/retail-react-app/app/components/shared/ui'
import PropTypes from 'prop-types'
import React from 'react'

const DrawerContainer = ({
    children,
    isDrawerOpen,
    onDrawerClose,
    direction
}) => {
    return (
        <Drawer
            isOpen={isDrawerOpen}
            placement={direction}
            onClose={onDrawerClose}
            size="cartDrawer"
            isFullHeight
        >
            {children}
        </Drawer>
    )
}

DrawerContainer.propTypes = {
    children: PropTypes.node,
    isDrawerOpen: PropTypes.bool,
    onDrawerClose: PropTypes.func,
    direction: PropTypes.oneOf(['bottom', 'right'])
}

export default DrawerContainer
