import {gql} from 'graphql-request'
import {
    LINK_FRAGMENT,
    MEDIA_FRAGMENT,
    RICH_TEXT_COMPONENT_FRAGMENT
} from './fragments'

export const ingredientsQuery = gql`
    ${MEDIA_FRAGMENT}
    ${LINK_FRAGMENT}
    ${RICH_TEXT_COMPONENT_FRAGMENT}
    query Ingredients($locale: String!) {
        ingredientCollection(
            locale: $locale
            where: {article_exists: true}
            order: [name_ASC]
        ) {
            items {
                contentfulTitle
                name
                code
                bannerImage {
                    ...MediaFragment
                }
                description {
                    ...RichTextFragment
                }
                article {
                    ... on Article {
                        cardCta {
                            ...LinkFragment
                        }
                    }
                }
            }
        }
    }
`
